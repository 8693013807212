
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "UserInfoWrapper" })
export default class UserInfoWrapper extends Vue {

    @Prop({ type: Boolean, default: true }) showBackArrow;
    @Prop({ type: Boolean, default: false }) showLogo;
    @Prop({ type: Boolean, default: false }) showEmailSentIcon;
    @Prop({ type: Boolean, default: false }) showSignInLink;
    @Prop({ type: Boolean, default: true }) showBackground;
    @Prop({ type: String }) header;
    @Prop({ type: String, default: "" }) headerDescription;
    @Emit() backArrowClicked (): boolean { return true; }

    get windowWidth(): number {
        return this.$store.getters.getWindowWidth;
    }

    navigateToPage(page: string): void {
        this.$router.push(page);
    }
}
