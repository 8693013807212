
import { Vue, Component } from "vue-property-decorator";
import PreviewModal from "../components/modals/PreviewModal.vue";
import LandingWrapper from "../components/LandingWrapper.vue";

@Component({ name: "Landing", components: { PreviewModal, LandingWrapper } })
export default class Landing extends Vue {
    preview = "";
    showPreviewModal = false;

    showPreview(preview: string) {
        this.preview = preview;
        this.showPreviewModal = true;
    }
}
