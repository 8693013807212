
import { resetUserPassword } from "@/apis";
import { getReadableErrorMessage } from "@/utility";
import { Vue, Component, Prop, Emit, Ref } from "vue-property-decorator";

@Component({ name: "ResetPasswordModal" })
export default class ResetPasswordModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (saveClicked: boolean): boolean { return saveClicked; }
    @Ref() resetPasswordFormRef!: any;

    saving = false;
    newPassword = "";
    passwordForMatch = "";
    errorMessage = "";
    rules = { 
        required: value => !!value || 'Required.',
    }

    async saveNewPassword(): Promise<void> {
        this.errorMessage = "";

        //validate form
        if (!this.resetPasswordFormRef.validate()) {
            return;
        } else if (this.newPassword !== this.passwordForMatch) {
            this.errorMessage = "Passwords do not match."
            return;
        }

        this.saving = true;
        const responseString = await resetUserPassword(this.newPassword);

        if (responseString !== "Password changed!") {
            this.errorMessage = getReadableErrorMessage(responseString);
            this.saving = false;
            return;
        }

        this.saving = false;
        this.hideModal(true);
    }

    hideModal(saveClicked = false): void {
        this.newPassword = "";
        this.passwordForMatch = "";
        this.errorMessage = "";
        this.modalHidden(saveClicked);
    }
}
