import { Vue, Component, Watch, Ref } from "vue-property-decorator";
import AddEditEquipment from "../../components/AddEditEquipment.vue";
import AddEditLogEntry from "../../components/AddEditLogEntry.vue";
import SelectEquipment from "../../components/SelectEquipment.vue";
import { DeleteEquipment } from "../../classes/DeleteEquipment";
import * as EquipmentOptions from "../../classes/equipment";
import _ from "lodash";
import {
  deleteEquipment,
  getAllUserEquipment,
  getLogEntryCountForEquipment,
  updateUserFirstSignIn,
} from "@/apis";
import moment from "moment";
import Snackbar from "../../components/Snackbar.vue";
import WelcomeModal from "../../components/modals/WelcomeModal.vue";
import OperatingSystemSelectionModal from "../../components/modals/OperatingSystemSelectionModal.vue";
import AddIconToHomeScreenModal from "../../components/modals/AddIconToHomeScreenModal.vue";
import AskToViewAddIconModal from "../../components/modals/AskToViewAddIconModal.vue";
import QuickTourModal from "../../components/modals/QuickTourModal.vue";
import PlanUpgradeModal from "../../components/modals/PlanUpgradeModal.vue";
import {
  FREE_PLAN_ENTRY_LIMIT,
  FREE_PLAN_EQUIPMENT_LIMIT,
  FREE_TEXT,
  HANDYMAN_PLAN_ENTRY_LIMIT,
  HANDYMAN_PLAN_EQUIPMENT_LIMIT,
  PRO_TEXT,
} from "@/constants";

@Component({
  name: "Home",
  components: {
    AddEditEquipment,
    AddEditLogEntry,
    SelectEquipment,
    Snackbar,
    WelcomeModal,
    OperatingSystemSelectionModal,
    AddIconToHomeScreenModal,
    AskToViewAddIconModal,
    QuickTourModal,
    PlanUpgradeModal,
  },
})
export default class Home extends Vue {
  //Use since no hidden event for dialog. SetTimeout maintains animation
  @Watch("showDeleteEquipmentModal")
  onModalHidden(): void {
    if (!this.showDeleteEquipmentModal) {
      setTimeout(() => {
        this.closeDeleteEquipModal();
      }, 1);
    }
  }

  @Ref() deleteRef!: any;

  moment = moment;
  fields: any[] = [
    {
      value: "actions",
      text: "Actions",
      sortable: false,
      cellClass: "col-action",
      width: 250,
      fixed: true,
    },
    { value: "name", text: "Nickname", class: "col-equipment-name" },
    { value: "category", text: "Category" },
    { value: "dateAdded", text: "Date Added", class: "col-date-added" },
    {
      value: "lastEntryDate",
      text: "Last Entry Date",
      class: "col-entry-date",
    },
  ];

  tableData = [];
  currentRowData: any = {};

  equipmentClassData: any = {};
  logEntryClassData: any = {};

  tableLoading = false;
  searchText = "";

  showEquipmentModal = false;
  showLogEntryModal = false;
  showSelectEquipModal = false;
  showDeleteEquipmentModal = false;
  deleteEquipmentDetails = new DeleteEquipment();
  deleting = false;
  deleteRules: any[] = [];
  equipmentDeletedSnackbar = false;
  showWelcomeModal = false;
  showQuickTourModal = false;
  showAskToViewAddIconModal = false;
  showWelcomeBackSnackbar = false;
  showViewInHelpLaterSnackbar = false;
  showPlanChangedSnackbar = false;
  showOperatingSystemSelectionModal = false;
  showAddIconToHomeScreenModal = false;
  selectedOperatingSystem = "";
  planUpgradeRequired = false;
  planUpgradeCategory = "";
  currentPlan = "";

  get windowWidth(): number {
    return this.$store.getters.getWindowWidth;
  }

  //TODO: is this used somewhere??
  get messageValid() {
    return this.deleteEquipmentDetails.validation.messageValid === false &&
      this.deleteEquipmentDetails.message.toLowerCase() !== "delete"
      ? false
      : null;
  }

  get userFirstName(): string {
    const user = this.$store.getters.getUser;
    if (user?.displayName) {
      return _.split(user.displayName, " ")[0];
    } else {
      return this.$store.getters.getUserProfile?.firstName ?? "";
    }
  }

  async mounted(): Promise<void> {
    const userProfile = this.$store.getters.getUserProfile;

    //show welcome modal if first sign in for user
    if (userProfile && userProfile.firstSignIn) {
      this.showWelcomeModal = true;

      //mark first sign in as false so user does not see welcome modal next time they load
      await updateUserFirstSignIn(this.$store.getters.getUser.uid);
    } else if (
      userProfile &&
      !userProfile.firstSignIn &&
      !this.$store.getters.getUserWelcomedBack
    ) {
      this.showWelcomeBackSnackbar = true;
      this.$store.commit("setUserWelcomedBack", true);
    } else if (this.$store.getters.getUserPlanChanged) {
      this.currentPlan = this.$store.getters.getUserCurrentPlan;
      this.showPlanChangedSnackbar = true;
      this.$store.commit("setUserPlanChanged", false);
    }

    this.getEquipment();
  }

  //get all equipment for main grid
  async getEquipment(): Promise<void> {
    this.tableLoading = true;

    /*setTimeout needed so getUser is populated. Fixes issue where it is not populated when user
    is signed in from preview session and opens a new tab for diymechaniclog */
    let userId = "";
    setTimeout(async () => {
      userId = this.$store.getters.getUser.uid;
      this.tableData = await getAllUserEquipment(userId);

      // add loading prop for loading animation on add entry button
      _.forEach(this.tableData, (record) => {
        record.loading = false;
      });

      this.tableLoading = false;
    }, 1);
  }

  askToViewIconModalIfOnMobile(): void {
    this.windowWidth < 600
      ? (this.showAskToViewAddIconModal = true)
      : this.openViewInHelpLaterSnackbar();
  }

  closeWelcomeModal(takeATour: boolean): void {
    this.showWelcomeModal = false;
    takeATour
      ? (this.showQuickTourModal = true)
      : this.askToViewIconModalIfOnMobile();
  }

  closeQuickTourModal(): void {
    this.showQuickTourModal = false;
    this.askToViewIconModalIfOnMobile();
  }

  closeAskToViewIconModal(viewIconModal: boolean): void {
    this.showAskToViewAddIconModal = false;
    viewIconModal
      ? (this.showOperatingSystemSelectionModal = true)
      : this.openViewInHelpLaterSnackbar();
  }

  operatingSystemSelectionModalHidden(): void {
    this.showOperatingSystemSelectionModal = false;
    this.openViewInHelpLaterSnackbar();
  }

  setSelectedOperatingSystem(operatingSystem: string): void {
    this.selectedOperatingSystem = operatingSystem;
    this.showOperatingSystemSelectionModal = false;
    this.showAddIconToHomeScreenModal = true;
  }

  openViewInHelpLaterSnackbar(): void {
    this.showViewInHelpLaterSnackbar = true;
  }

  addIconToHomeScreenModalHidden(): void {
    this.showAddIconToHomeScreenModal = false;
    this.openViewInHelpLaterSnackbar();

    //TODO: Instead of using timeouts I could check to see when dialog is hidden from DOM.
    setTimeout(() => {
      this.selectedOperatingSystem = "";
    }, 500);
  }

  openSelectEquipModal(): void {
    const currentPlan = this.$store.getters.getUserCurrentPlan;

    // check for non pro users
    if (currentPlan !== PRO_TEXT) {
      const equipmentLimit =
        currentPlan === FREE_TEXT
          ? FREE_PLAN_EQUIPMENT_LIMIT
          : HANDYMAN_PLAN_EQUIPMENT_LIMIT;

      if (this.tableData.length === equipmentLimit) {
        this.planUpgradeCategory = "equipment";
        this.planUpgradeRequired = true;
        return;
      }
    }

    this.showSelectEquipModal = true;
  }

  openAddEquipModal(equipment: any): void {
    this.equipmentClassData = equipment;
    this.showEquipmentModal = true;
  }

  closeEquipmentModal(): void {
    this.equipmentClassData = {};
    this.showEquipmentModal = false;
  }

  openDeleteEquipModal(cellData: any): void {
    this.currentRowData = cellData;
    this.showDeleteEquipmentModal = true;
  }

  async deleteEquipment(): Promise<void> {
    this.deleteRules = [
      (value: string) =>
        value === "delete" || value === "Delete" || "Required.",
    ];
    this.deleteRef.validate();

    if (this.deleteEquipmentDetails.message.toLowerCase() !== "delete") {
      return;
    }

    this.deleting = true;
    await deleteEquipment(
      this.currentRowData.id,
      this.$store.getters.getUser.uid
    );

    this.showDeleteEquipmentModal = false;
    this.equipmentDeletedSnackbar = true;

    //TODO: I can remove this call to firestore and just remove this equipment from the list of equipment to save a query hit
    await this.getEquipment();
    this.deleting = false;
  }

  closeDeleteEquipModal(): void {
    this.currentRowData = {};
    this.deleteEquipmentDetails = new DeleteEquipment();
    this.deleteRules = [];
  }

  async openLogEntryModal(cellData: any): Promise<void> {
    cellData.loading = true;
    this.currentRowData = cellData;
    this.getClassForLogEntryModal(cellData);

    const currentPlan = this.$store.getters.getUserCurrentPlan;
    const entryCountForEquipment = await getLogEntryCountForEquipment(
      cellData.id
    );

    // check for non pro users
    if (currentPlan !== PRO_TEXT) {
      const entryLimit =
        currentPlan === FREE_TEXT
          ? FREE_PLAN_ENTRY_LIMIT
          : HANDYMAN_PLAN_ENTRY_LIMIT;

      if (entryCountForEquipment === entryLimit) {
        this.planUpgradeCategory = "entry";
        this.planUpgradeRequired = true;
        cellData.loading = false;
        return;
      }
    }

    cellData.loading = false;
    this.showLogEntryModal = true;
  }

  closeLogEntryModal(): void {
    this.currentRowData = {};
    this.showLogEntryModal = false;

    //TODO: find a better way to do this
    //setTimeout used so fields are not removed before modal is closed. Looks a lot cleaner this way
    setTimeout(() => {
      this.logEntryClassData = {};
    }, 200);
  }

  //Load data for specific equipment when details icon clicked
  async loadDetails(rowData: any): Promise<void> {
    this.$store.commit("setSelectedEquipment", rowData);
    this.$router.push("details");
  }

  //Get new class when add entry plus icon clicked to generate fields for log entry modal
  getClassForLogEntryModal(cellData: any): void {
    const className = cellData.category.replace(/ /g, "");

    this.logEntryClassData = new EquipmentOptions[`${className}`]();
  }

  closePlanUpgradeRequiredModal() {
    this.planUpgradeRequired = false;
    this.planUpgradeCategory = "";
  }

  hidePlanChangedSnackbar() {
    this.showPlanChangedSnackbar = false;
    this.currentPlan = "";
  }
}
