
import { Vue, Component } from "vue-property-decorator";
import { contactUsMailToString } from "@/utility";

@Component({ name: "LandingWrapper" })
export default class LandingWrapper extends Vue {
    contactUsMailToString = contactUsMailToString;
    footerLinksWrapThreshold = 425; // px threshold at which footer links wrap

    get smallScreen (): boolean {
        return this.$store.getters.getWindowWidth < 762;
    }

    //width at which nav links wrap for small screens
    get navWrapSmallScreen (): boolean {
        return this.$store.getters.getWindowWidth <= 452;
    }

    get footerLinksWrapping() {
        return this.footerLinksWrapThreshold > this.$store.getters.getWindowWidth;
    }

    navigateToPage(page: string): void {
        this.$router.push(page);
    }
}
