
import { Vue, Component } from 'vue-property-decorator';
import Snackbar from "./components/Snackbar.vue";
import { cacheImage, contactUsMailToString } from "./utility";
import { FREE_TEXT, HANDYMAN_TEXT, PRO_TEXT } from "./constants";
import _ from 'lodash';

  @Component({ name: 'App', components: { Snackbar } })
export default class App extends Vue {
    $vuetify: any;
    showNavDrawer = false;
    themeSavedSnackbar = false;
    darkModeSwitchVal = false;
    contactUsMailToString = contactUsMailToString;
    freeText = FREE_TEXT;
    handymanText = HANDYMAN_TEXT;
    proText = PRO_TEXT;
    preLoadImagePaths = [
        "img/logo-black.01367af1.png",
        "img/blue-background.4ed7d3cc.png",
        "img/add-equipment-play.bc0df5b0.png",
        "img/add-log-entry-play.9b94a674.jpg",
        "img/add-guide-play.e3038bc0.png",
        "img/add-your-equipment.b903d2cf.gif",
        "img/log-what-you-do.e3dfafea.gif",
        "img/create-a-guide.7714a9d4.gif"
    ];

    get routeName (): string {
        const selectedEquip = this.$store.getters.getSelectedEquipment;

        //TODO: Find a cleaner way to do this.
        if (this.$route.name === "About") {
            return "About";
        } else if (this.$route.name === "Help") {
            return "Help";
        } else if (this.$route.name === "Profile Settings") {
            return "Profile Settings";
        } else if (this.$route.name === "Plans") {
            return "Plans";
        } else if (selectedEquip !== null && this.$route.name !== 'Home') {
            return `${selectedEquip.name}`;
        } else {
            return this.$route.name;
        }
    }

    //only password provider requires email verification. Google automatically verifies, and Facebook I cannot force users to verify their email
    get userSignedInWithAccess(): boolean {
        const user = this.$store.getters.getUser;
        if (!user) return false;

        const needsEmailVerification = user.providerData[0].providerId === "password";

        return (needsEmailVerification ? user.emailVerified : true) &&
        this.routeName !== "Sign In"    //need reference to routeName to delay navbar from rendering sooner than I want
    }

    get windowWidth (): number {
        return this.$store.getters.getWindowWidth;
    }

    get logoFilePath (): string {
        return this.$vuetify.theme.dark ? './assets/logo-white.png' : './assets/logo-black.png';
    }

    get showApp(): boolean {
        let user = this.$store.getters.getUser;
        let userProfile = this.$store.getters.getUserProfile;
        
        //signed out
        if (!user) return true;
        if (userProfile) {
            this.$vuetify.theme.dark = userProfile.darkMode;
            this.darkModeSwitchVal = userProfile.darkMode;
            return true;
        }

        return true;
    }

    get userPhotoUrl (): string {
        return this.$store.getters.getUser.photoURL;
    }

    //Used as a fallback if user image retrieval fails
    get userInitial (): string {
        return this.$store.getters.getUser.displayName.substring(0, 1);
    }

    get userPlan (): string {
        return this.$store.getters.getUserCurrentPlan;
    }

    beforeCreate (): void {
        /* Return to Home page if page reloaded from details page */
        if (window.location.href.includes('details')) {
            this.$router.push("/equipment");
        }
    }

    async created (): Promise<void> {
        this.setWindowHeight();
        this.setWindowWidth();
        window.addEventListener('resize', this.setWindowWidth);
        window.addEventListener('resize', this.setWindowHeight);
    }

    mounted() {
        _.forEach(this.preLoadImagePaths, (path) => {
            cacheImage(path);
        });
    }

    setWindowHeight (): void {
        this.$store.commit('setWindowHeight', window.innerHeight);
    }

    setWindowWidth (): void {
        this.$store.commit('setWindowWidth', window.innerWidth);
    }

    signOut(): void {
        //hide navbar after sign out so it does not show up upon sign in
        this.showNavDrawer = false;

        this.$store.dispatch("signOut", true);

        //TODO: Improve this transition here so that it does not flash white then sign the user out
        //set theme to light theme on sign out
        this.$vuetify.theme.dark = false;
        const userProfile = this.$store.getters.getUserProfile;
        userProfile.darkMode = this.$vuetify.theme.dark;
    }

    toggleTheme (): void {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        const userProfile = this.$store.getters.getUserProfile;
        userProfile.darkMode = this.$vuetify.theme.dark;

        //update vuex
        this.$store.commit("setUserProfile", userProfile);
        //update document for user in users collection
        this.$store.dispatch("updateTheme", userProfile.darkMode);

        this.themeSavedSnackbar = true;
    }

    hideNavBar(): void {
        this.showNavDrawer = !this.showNavDrawer;
    }
}

