import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithRedirect,
} from "firebase/auth";
import { SignInInterface, UserDataInterface } from "@/interfaces";
import { db, auth, googleProvider, facebookProvider, app } from "@/firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    windowHeight: 0,
    windowWidth: 0,
    selectedEquipment: null,
    user: null,
    userProfile: null,
    userWelcomedBack: false,
    planSelectedWhileSignedOut: null,
    userCurrentPlan: "",
    userPlanChanged: false,
    stripeActiveSubscription: null,
  },
  mutations: {
    setWindowHeight(state, height) {
      state.windowHeight = height;
    },
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
    setSelectedEquipment(state, equipment) {
      if (equipment !== null) {
        state.selectedEquipment = equipment;
      } else {
        state.selectedEquipment = null;
      }
    },
    setUser(state, userData) {
      state.user = userData;
    },
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile;
    },
    setUserWelcomedBack(state, userWelcomedBack) {
      state.userWelcomedBack = userWelcomedBack;
    },
    setPlanSelectedWhileSignedOut(state, planSelectedWhileSignedOut) {
      state.planSelectedWhileSignedOut = planSelectedWhileSignedOut;
    },
    setUserCurrentPlan(state, userCurrentPlan) {
      state.userCurrentPlan = userCurrentPlan;
    },
    setUserPlanChanged(state, userPlanChanged) {
      state.userPlanChanged = userPlanChanged;
    },
    setStripeActiveSubscription(state, stripeActiveSubscription) {
      state.stripeActiveSubscription = stripeActiveSubscription;
    },
  },
  actions: {
    async signUp({ dispatch, commit }, form: UserDataInterface) {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );

      commit("setUser", user);
      await dispatch("setUserData", form);
    },

    async signInWithEmailPassword({ dispatch, commit }, form: SignInInterface) {
      const { user } = await signInWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );

      commit("setUser", user);
      await dispatch("getUserData");
    },

    async signInWithGoogle() {
      await signInWithRedirect(auth, googleProvider);
      //user and userProfile set in main.ts after redirect
    },

    async signInWithFacebook() {
      await signInWithRedirect(auth, facebookProvider);
      //user and userProfile set in main.ts after redirect
    },

    async signOut({ commit }, routeToSignIn = false) {
      await signOut(auth);

      //reset vuex store values
      commit("setUserProfile", null);
      commit("setUser", null);
      commit("setUserWelcomedBack", false);
      commit("setSelectedEquipment", null);
      commit("setPlanSelectedWhileSignedOut", null);
      commit("setUserCurrentPlan", "");
      commit("setUserPlanChanged", false);
      commit("setStripeActiveSubscription", null);

      //clear session storage that was stored above for maintaining state during refresh
      sessionStorage.clear();

      if (routeToSignIn) router.push("/");
    },

    async sendUserEmailVerification() {
      await sendEmailVerification(auth.currentUser);
    },

    async sendUserPasswordResetEmail({ state }, email: string) {
      await sendPasswordResetEmail(auth, email);

      router.push("/forgot-password-email");
    },

    async checkForUserData({ state }): Promise<boolean> {
      const docRef = doc(db, "users", state.user.uid);
      const docSnap = await getDoc(docRef);

      return docSnap.exists();
    },

    //get document of user from "users" collection and set userProfile in vuex
    async getUserData({ state, commit }) {
      const docRef = doc(db, "users", state.user.uid);
      const docSnap = await getDoc(docRef);

      //get checkoutSessions collection from user
      const checkoutSessionsQuery = query(
        collection(db, `users/${state.user.uid}/checkout_sessions`)
      );

      const checkoutSessions = [];
      const checkoutSessionsQuerySnap = await getDocs(checkoutSessionsQuery);
      checkoutSessionsQuerySnap.forEach((doc) => {
        checkoutSessions.push(doc.data());
      });

      const subscriptionsQuery = query(
        collection(db, `users/${state.user.uid}/subscriptions`)
      );

      const subscriptions = [];
      const subscriptionsQuerySnap = await getDocs(subscriptionsQuery);
      subscriptionsQuerySnap.forEach((doc) => {
        subscriptions.push(doc.data());
      });

      if (docSnap.exists()) {
        commit("setUserProfile", {
          ...docSnap.data(),
          checkoutSessions: checkoutSessions,
          subscriptions: subscriptions,
        });
      } else {
        console.log("User document does not exist!");
      }
    },

    //create a document in "users" collection for the user
    async setUserData({ state }, form?) {
      let newDoc = {};
      if (form) {
        newDoc = {
          firstName: form.firstName,
          lastName: form.lastName,
          darkMode: false,
          firstSignIn: true,
          freeUser: false,
        };
      } else {
        newDoc = { darkMode: false, firstSignIn: true };
      }

      try {
        await setDoc(doc(db, "users", state.user.uid), newDoc);
      } catch (e) {
        console.error("Error adding document");
      }
    },

    //send user to Stripe customer portal
    async sendToStripeCustomerPortal({ state }) {
      const functions = getFunctions(app);
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data }: any = await functionRef({
        returnUrl: window.location.origin,
      });

      window.location.assign(data.url);
    },

    //Cancel users active paid subscription
    async cancelUserSubscription({ state }) {
      const functions = getFunctions(app);
      const cancelSubscriptionFunctionRef = httpsCallable(
        functions,
        "cancelSubscription"
      );

      try {
        const { data } = await cancelSubscriptionFunctionRef({
          subscriptionId: state.stripeActiveSubscription.items[0].subscription,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    //Change users paid plan to another paid plan
    async changeUserPaidPlan({ state }, newPrice) {
      const functions = getFunctions(app);
      const changePaidPlansFunctionRef = httpsCallable(
        functions,
        "changePaidPlans"
      );

      try {
        const { data } = await changePaidPlansFunctionRef({
          subscriptionId: state.stripeActiveSubscription.items[0].subscription,
          subscriptionItemId: state.stripeActiveSubscription.items[0].id,
          newPrice: newPrice,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    //send customer to a Stripe Checkout session
    async sendToStripeCheckoutSession(
      { state },
      payload: any
    ): Promise<boolean> {
      try {
        const docRef = await addDoc(
          collection(db, `users/${payload.userId}/checkout_sessions`),
          {
            price: payload.planPrice,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
          }
        );

        onSnapshot(docRef, (snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });

        return true;
      } catch (e) {
        console.log(e);
        alert("An error occurred while selecting this plan. Please try again.");

        return false;
      }
    },

    //update dark mode when toggle is changed
    async updateTheme({ state }, darkMode: boolean) {
      const docRef = doc(db, "users", state.user.uid);

      await updateDoc(docRef, {
        darkMode: darkMode,
      });
    },
  },
  getters: {
    getWindowHeight: (state) => {
      return state.windowHeight;
    },
    getWindowWidth: (state) => {
      return state.windowWidth;
    },
    getSelectedEquipment: (state) => {
      return state.selectedEquipment;
    },
    getUser: (state) => {
      return state.user;
    },
    getUserProfile: (state) => {
      return state.userProfile;
    },
    getUserWelcomedBack: (state) => {
      return state.userWelcomedBack;
    },
    getPlanSelectedWhileSignedOut: (state) => {
      return state.planSelectedWhileSignedOut;
    },
    getUserCurrentPlan: (state) => {
      return state.userCurrentPlan;
    },
    getUserPlanChanged: (state) => {
      return state.userPlanChanged;
    },
    getStripeActiveSubscription: (state) => {
      return state.stripeActiveSubscription;
    },
  },
});

export default store;
