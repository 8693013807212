

import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';
import _ from 'lodash';
import { scrollToElement } from '@/utility';
import { addFileToStorage, addNewLogEntry, deleteFileFromStorage, getFileFromStorage, getSingleEquipment, removeFileNameFromEntryForFileDeleted, updateExistingLogEntry } from '@/apis';

    @Component({ name: 'AddEditLogEntry' })

export default class AddEditLogEntry extends Vue {
    @Prop({ default: false })
    showModal: boolean;

    @Prop({ default: null })
    entryId: string;

    @Prop({ default: null })
    equipmentId: string;

    @Prop({ default: {} })
    equipmentData: any;

    @Prop({ default: '' })
    action: string;

    @Prop({ default: false })
    readOnly: boolean;

    @Watch('showModal')
    onShowModalChanged (): void {
        this.modalVisible = this.showModal;

        if (this.showModal) {
            this.otherChecked = _.find(this.equipmentData.logEntryFields, { label: 'Other' }).value;
        }
    }

    @Ref() addEntryRef!: any;

    @Emit() editEntry (): boolean { return true; }
    @Emit() refreshEquipDetails (): boolean { return true; }
    @Emit() refreshEquipLogEntries (): boolean { return true; }
    @Emit() viewEquipmentDetails (): boolean { return true; }

    modalVisible = false;
    $parent: any;
    taskMarked = false;
    saving = false;
    file = null;
    downloadingFile = false;
    deleteEntryFileModalVisible = false;
    deleting = false;
    otherChecked = false;

    get windowWidth (): number {
        return this.$store.getters.getWindowWidth;
    }

    otherValueChange (val: any): void {
        this.otherChecked = val;

        if (!val) {
            const entry = _.find(this.equipmentData.logEntryFields, { label: 'Other Description' });
            entry.value = null;
        }
    }

    async saveEntry (): Promise<void> {
        //Validate based on rules within class to update UI
        this.addEntryRef.validate();

        //Check values using class method to prevent from saving
        const validationResult = this.equipmentData.validateEntry();
        this.taskMarked = false;

        if (!validationResult.valid) {
            return;
        } else if (validationResult.valid && !validationResult.taskMarked) {
            this.taskMarked = true;
            scrollToElement("markTaskAlert");
            return;
        }

        this.saving = true;
        let newLogEntryId = "";
        if (this.file) {
            const fileField = _.find(this.equipmentData.logEntryFields, { label: "Image or File" });

            //delete existing file so I only have one existing file for this entry
            //I setup file within folder for entry so in the future I can have more than one file per entry
            if (fileField.value) {
                //delete file here
                const filePathFileToDelete = `${this.$store.getters.getUser.uid}/entries/${this.entryId}/${fileField.value}`
                await deleteFileFromStorage(filePathFileToDelete);
            }

            //set file name in details object so I can use it below file input to show user name of file currently saved
            fileField.value = this.file.name;
        }

        if (this.entryId !== null) {
            await updateExistingLogEntry(this.entryId, this.equipmentData.logEntryFields);
        } else {
            newLogEntryId = await addNewLogEntry(this.equipmentId, this.equipmentData.logEntryFields);

            //get updated equipment for lastEntryDate that was updated
            this.$store.commit('setSelectedEquipment', await getSingleEquipment(this.equipmentId))
        }

        if (this.file) {
            const logEntryId = this.entryId ?? newLogEntryId;
            const filePathNewFile = `${this.$store.getters.getUser.uid}/entries/${logEntryId}/${this.file.name}`
            await addFileToStorage(this.file, filePathNewFile);
            this.file = null;
        }

        if (!window.location.href.includes('details')) {
            //navigate to equipment details if entry added on home screen
            this.viewEquipmentDetails();
        } else {
            //Only want to refresh equipment details table if a new entry was added (not updated) so I can update lastEntryDate
            if (!this.entryId) this.refreshEquipDetails();            
            this.refreshEquipLogEntries();
        }
        
        this.closeModal();
        this.saving = false;
    }

    closeModal (): void {
        this.taskMarked = false;
        this.otherChecked = false;
        this.$parent.closeLogEntryModal();
    }

    async downloadFile (): Promise<void> {
        this.downloadingFile = true;

        //TODO: I can make this filePath a computed prop. I reference it like three times
        const fileField = _.find(this.equipmentData.logEntryFields, { label: "Image or File" });
        await getFileFromStorage(`${this.$store.getters.getUser.uid}/entries/${this.entryId}/${fileField.value}`);
        
        this.downloadingFile = false;
    }

    async deleteFile (): Promise<void> {
        this.deleting = true;
        //get filename from fileField value to pass to path so I can delete file from storage
        const fileField = _.find(this.equipmentData.logEntryFields, { label: "Image or File" });
        await deleteFileFromStorage(`${this.$store.getters.getUser.uid}/entries/${this.entryId}/${fileField.value}`);

        await removeFileNameFromEntryForFileDeleted(this.entryId);

        this.deleteEntryFileModalVisible = false;
        this.deleting = false;
        this.refreshEquipDetails();
        this.file = null;

        //set value to null so it does not show file name for file that was deleted
        fileField.value = null;
    }
}

