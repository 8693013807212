
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";

@Component({ name: "Snackbar" })
export default class Snackbar extends Vue {
    @Prop({ type: Number, default: 3000 }) timeOut;
    @Prop({ type: Boolean, default: false }) showSnackbar;
    @Prop({ type: Boolean, default: true }) showIcon;
    @Prop({ type: String, default: "success" }) type;

    //Used Watch & Emit instead of timeout in v-snackbar api since it was causing overwrite prop warnings
    @Emit() hideSnackbar (): boolean { return true; }
    @Watch('showSnackbar')
    onShowSnackbarChanged (): void {
        if (this.showSnackbar) {
            setTimeout(() => {
                this.hideSnackbar();
            }, this.timeOut)
        }
    }

    get snackBarColor(): string {
        switch(this.type) {
            case "info":
                return "";
            case "welcome":
                return "primary";
            default:
                return "green"
        }
    }

    get snackBarIcon(): string {
        switch(this.type) {
            case "info":
                return "mdi-information";
            case "welcome":
                return "mdi-account";
            default:
                return "mdi-checkbox-marked-circle"
        }
    }

    //By default, v-snacbar times out after 5 seconds. I cannot just use timeout attribute since it mutates a prop.
    //therefore I have to add 1 second to the actual timeout I want so snackbar stays open until I set showSnackbar to false
    //via setTimeout in Watch decorator
    get adjustedTimeout(): number {
        return this.timeOut + 1000;
    }
}
