
import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';
import { EquipmentSelection } from '../classes/EquipmentSelection';
import * as EquipmentOptions from '../classes/equipment';

@Component({ name: 'SelectEquipment' })

export default class SelectEquipment extends Vue {
    @Prop({ default: false })
    showModal: boolean;

    @Watch('showModal')
    onShowModalChanged (): void { this.showModal ? this.modalVisible = true : this.modalVisible = false; }

    @Ref()equipRef!: any;

    @Emit()
    selected () { return this.equipSelection.equipSelected; }

    modalVisible = false;
    $parent: any;
    equipSelection = new EquipmentSelection();
    allEquipOptions: any = [
        { header: 'Large Equipment' },
        { value: new EquipmentOptions.ATV(), text: 'ATV' },
        { value: new EquipmentOptions.Boat(), text: 'Boat' },
        { value: new EquipmentOptions.GolfCart(), text: 'Golf Cart' },
        { value: new EquipmentOptions.Motorcycle(), text: 'Motorcycle' },
        { value: new EquipmentOptions.Snowmobile(), text: 'Snowmobile' },
        { value: new EquipmentOptions.Tractor(), text: 'Tractor' },
        { value: new EquipmentOptions.Vehicle(), text: 'Vehicle' },
        { header: 'Misc' },
        { value: new EquipmentOptions.Dehumidifier(), text: 'Dehumidifier' },
        { value: new EquipmentOptions.Furnace(), text: 'Furnace' },
        { value: new EquipmentOptions.GeneralEquipment(), text: 'General Equipment' },
        { header: 'Small Equipment' },
        { value: new EquipmentOptions.Generator(), text: 'Generator' },
        { value: new EquipmentOptions.PressureWasher(), text: 'Pressure Washer' },
        { value: new EquipmentOptions.SnowBlower(), text: 'Snow Blower' },
        { header: 'Yard Equipment' },
        { value: new EquipmentOptions.Chainsaw(), text: 'Chainsaw' },
        { value: new EquipmentOptions.Edger(), text: 'Edger' },
        { value: new EquipmentOptions.Mower(), text: 'Mower' },
        { value: new EquipmentOptions.WeedTrimmer(), text: 'Weed Trimmer' }
    ];

    //equipment selected to be added
    selectEquipment (): void {
        this.equipSelection.validation.equipValid = [(value: any) => !!value || 'Required.'];
        this.equipRef.validate();

        if (this.equipSelection.equipSelected === null) {
            return;
        }

        //Reset option value to new class to clear any field values
        const className = this.equipSelection.equipSelected.name.replace(/ /g, '');
        this.equipSelection.equipSelected = new EquipmentOptions[`${className}`]();

        //emit equip class
        this.selected();
        this.closeModal();
    }

    closeModal (): void {
        this.$parent.showSelectEquipModal = false;
        this.equipSelection = new EquipmentSelection();
    }
}
