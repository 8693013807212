
import { deleteUserAccount } from "@/apis";
import { Vue, Component, Prop, Emit, Ref } from "vue-property-decorator";

@Component({ name: "DeleteAccountModal" })
export default class DeleteAccountModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (): boolean { return true; }
    @Ref() deleteRef!: any;

    deleting = false;
    deleteMessage = "";
    deleteRules = [];   

    async deleteAccount(): Promise<void> {
        this.deleteRules = [(value: string) =>
            value === "delete" || value === "Delete" || "Required.",
        ];

        this.deleteRef.validate();

        if (this.deleteMessage.toLowerCase() !== "delete") {
            return;
        }

        this.deleting = true;
        try {
            const userId = this.$store.getters.getUser.uid;
            await deleteUserAccount(userId);
            this.modalHidden();
            this.deleting = false;

            //reset to light theme
            this.$vuetify.theme.dark = false;
            const userProfile = this.$store.getters.getUserProfile;
            userProfile.darkMode = this.$vuetify.theme.dark;

            //reset vuex store values
            this.$store.commit("setUserProfile", null);
            this.$store.commit("setUser", null);
            this.$store.commit("setUserWelcomedBack", false);
            this.$store.commit("setSelectedEquipment", null);
            this.$store.commit("setPlanSelectedWhileSignedOut", null);
            this.$store.commit("setUserCurrentPlan", "");
            this.$store.commit("setUserPlanChanged", false);
            this.$store.commit("setStripeActiveSubscription", null);

            this.$router.push("/");
        } catch (error) {
            this.deleting = false;
        }
    }

    hideModal(): void {
        this.deleteMessage = "";
        this.modalHidden();
    }
}
