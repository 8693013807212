import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import { auth } from "./firebase";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import {
  faPlus,
  faTrash,
  faEdit,
  faCheck,
  faList,
  faInfoCircle,
  faInfo,
  faCloudDownloadAlt,
  faQuestionCircle,
  faTimes,
  faHome,
  faUserCircle,
  faSignOutAlt,
  faSignInAlt,
  faCar,
  faClipboardList,
  faListOl,
  faDollarSign,
  faMobileAlt,
  faLock,
  faUserPlus,
  faSun,
  faMoon,
  faArrowLeft,
  faQuestion,
  faCog,
  faEnvelope,
  faLifeRing,
  faWrench,
  faTools,
  faToolbox,
  faCarSide,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { getCustomersActiveSubscription, updateUserAsFreeUser } from "./apis";
import { FREE_TEXT } from "./constants";

let app;
auth.onAuthStateChanged(async (user) => {
  if (user) {
    store.commit("setUser", user);

    //If signed in through google/facebook I cannot create user doc on sign up. Must create it here if first time signing in with google/facebook
    if (user.providerData[0].providerId !== "password") {
      const userDocExists = await store.dispatch("checkForUserData");
      if (!userDocExists) await store.dispatch("setUserData");
    }

    await store.dispatch("getUserData");

    let currentPlanName = "";
    const existingActiveSubscription = await getCustomersActiveSubscription(
      user.uid
    );

    //user has an existing active subscription (paid plan)
    if (existingActiveSubscription !== null) {
      currentPlanName = existingActiveSubscription.items[0].price.product.name;

      //store paid subscription in vuex
      store.commit("setStripeActiveSubscription", existingActiveSubscription);

      // user changed from free plan -> paid plan
      if (store.getters.getUserProfile.freeUser) {
        // show "changed plans" snackbar since user went from free plan -> paid plan
        store.commit("setUserPlanChanged", true);

        // reset to false since no longer a free user
        await updateUserAsFreeUser(user.uid, false);
      }
    } else if (store.getters.getUserProfile.freeUser) {
      currentPlanName = FREE_TEXT;

      //set back to null since no paid subscription is active. This would happen if paid user switched to free plan from paid plan
      if (store.getters.getStripeActiveSubscription !== null) {
        store.commit("setStripeActiveSubscription", null);
      }
    }

    store.commit("setUserCurrentPlan", currentPlanName);

    if (store.getters.getPlanSelectedWhileSignedOut !== null) {
      router.push("/plans");
    }
  }

  if (!app) {
    library.add(
      faPlus,
      faTrash,
      faEdit,
      faCheck,
      faList,
      faInfoCircle,
      faInfo,
      faCloudDownloadAlt,
      faQuestionCircle,
      faTimes,
      faHome,
      faUserCircle,
      faSignOutAlt,
      faSignInAlt,
      faCar,
      faCarSide,
      faDatabase,
      faClipboardList,
      faUserPlus,
      faListOl,
      faDollarSign,
      faMobileAlt,
      faLock,
      faSun,
      faMoon,
      faArrowLeft,
      faQuestion,
      faCog,
      faEnvelope,
      faLifeRing,
      faWrench,
      faTools,
      faToolbox,
      faApple,
      faAndroid
    );

    Vue.component("font-awesome-icon", FontAwesomeIcon);

    Vue.config.productionTip = false;

    Vue.use(Vuetify);

    app = new Vue({
      router,
      store,
      vuetify: new Vuetify(),
      render: (h) => h(App),
    }).$mount("#app");
  }
});
