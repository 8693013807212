import {
  updateUserAsFreeUser,
  getCustomersActiveSubscription,
  userAllowedToChangePlans,
} from "@/apis";
import {
  HANDYMAN_PLAN_PRICE,
  HANDYMAN_PLAN_PRICE_TEST,
  PRO_PLAN_PRICE,
  PRO_PLAN_PRICE_TEST,
  FREE_PLAN_EQUIPMENT_LIMIT,
  FREE_PLAN_ENTRY_LIMIT,
  FREE_PLAN_GUIDE_LIMIT,
  HANDYMAN_PLAN_EQUIPMENT_LIMIT,
  HANDYMAN_PLAN_ENTRY_LIMIT,
  HANDYMAN_PLAN_GUIDE_LIMIT,
  PRO_TEXT,
  FREE_TEXT,
  HANDYMAN_TEXT,
  FREE_PLAN_COST,
  HANDYMAN_PLAN_COST,
  PRO_PLAN_COST,
} from "@/constants";
import { Vue, Component, Ref } from "vue-property-decorator";
import CurrentPlanModal from "../modals/CurrentPlanModal.vue";
import PlanNotSelectableModal from "../modals/PlanNotSelectableModal.vue";
import ConfirmFreePlanSelectionModal from "../modals/ConfirmFreePlanSelectionModal.vue";
import SwitchPlanConfirmationModal from "../modals/SwitchPlanConfirmationModal.vue";

@Component({
  name: "PlansContent",
  components: {
    CurrentPlanModal,
    PlanNotSelectableModal,
    ConfirmFreePlanSelectionModal,
    SwitchPlanConfirmationModal,
  },
})
export default class PlansContent extends Vue {
  @Ref() proExpansionPanel;
  @Ref() freeButton!: any;
  @Ref() handymanButton!: any;
  @Ref() proButton!: any;

  loadingFreePlanCheckoutSession = false;
  loadingHandymanPlanCheckoutSession = false;
  loadingProPlanCheckoutSession = false;
  showCurrentPlanSelectedModal = false;
  showPlanNotSelectableModal = false;
  showConfirmFreePlanSelectionModal = false;
  showSwitchPlanConfirmationModal = false;
  planToSwitchTo = "";

  freeText = FREE_TEXT;
  handymanText = HANDYMAN_TEXT;
  proText = PRO_TEXT;

  freePlanCost = FREE_PLAN_COST;
  handymanPlanCost = HANDYMAN_PLAN_COST;
  proPlanCost = PRO_PLAN_COST;

  freePlanEquipmentLimit = FREE_PLAN_EQUIPMENT_LIMIT;
  freePlanEntryLimit = FREE_PLAN_ENTRY_LIMIT;
  freePlanGuideLimit = FREE_PLAN_GUIDE_LIMIT;
  handymanPlanEquipmentLimit = HANDYMAN_PLAN_EQUIPMENT_LIMIT;
  handymanPlanEntryLimit = HANDYMAN_PLAN_ENTRY_LIMIT;
  handymanPlanGuideLimit = HANDYMAN_PLAN_GUIDE_LIMIT;

  get planTitle() {
    return this.$store.getters.getUserCurrentPlan === ""
      ? "Select a Plan to Continue"
      : "Select a Plan";
  }

  get userIsSignedIn() {
    return this.$store.getters.getUser !== null;
  }

  mounted() {
    const planSelectedWhileSignedOutValue = this.$store.getters
      .getPlanSelectedWhileSignedOut;

    /* click the same button the user did when they were signed out to initiate
    the necessary logic */
    if (planSelectedWhileSignedOutValue !== null) {
      switch (planSelectedWhileSignedOutValue) {
        case this.freeText:
          this.freeButton.click(Event);
          break;
        case HANDYMAN_PLAN_PRICE:
          this.handymanButton.click(Event);
          break;
        case PRO_PLAN_PRICE:
          this.proButton.click(Event);
          break;
        default:
          break;
      }

      // reset value as no longer needed
      this.$store.commit("setPlanSelectedWhileSignedOut", null);
    }
  }

  async selectPlan(plan: string) {
    let planPrice = "";
    switch (plan) {
      case this.freeText:
        planPrice = this.freeText;
        break;
      case this.handymanText:
        planPrice = HANDYMAN_PLAN_PRICE;
        break;
      case this.proText:
        planPrice = PRO_PLAN_PRICE;
        break;
      default:
        alert("Error: Not a supported plan price");
        return;
    }

    // force sign in when selecting a plan
    if (!this.$store.getters.getUser) {
      this.$store.commit("setPlanSelectedWhileSignedOut", planPrice);
      this.$router.push("/signin");
      return;
    }

    // check for existing user plan
    if (this.$store.getters.getUserCurrentPlan !== "") {
      this.switchUserPlans(plan);
    } else {
      //free plan selected
      if (planPrice === this.freeText) {
        this.showConfirmFreePlanSelectionModal = true;
      } else {
        //paid plan selected
        plan === this.handymanText
          ? (this.loadingHandymanPlanCheckoutSession = true)
          : (this.loadingProPlanCheckoutSession = true);
        const success = await this.$store.dispatch(
          "sendToStripeCheckoutSession",
          { userId: this.$store.getters.getUser.uid, planPrice: planPrice }
        );
        if (!success) {
          this.resetSelectPlanButtonLoading();
        }
      }
    }
  }

  async switchUserPlans(plan: string) {
    const currentPlan = this.$store.getters.getUserCurrentPlan;

    //don't let user select plan they already have
    if (currentPlan === plan) {
      this.showCurrentPlanSelectedModal = true;
      return;
    }

    //check if plan selected is a downgrade from current plan
    if (
      currentPlan === this.proText ||
      (currentPlan === this.handymanText && plan === this.freeText)
    ) {
      // handyman plan -> free plan
      if (currentPlan === this.handymanText) {
        this.loadingFreePlanCheckoutSession = true;

        const allowedToSelectPlan = await userAllowedToChangePlans(
          this.$store.getters.getUser.uid,
          FREE_PLAN_EQUIPMENT_LIMIT,
          FREE_PLAN_ENTRY_LIMIT,
          FREE_PLAN_GUIDE_LIMIT
        );

        this.loadingFreePlanCheckoutSession = false;
        if (!allowedToSelectPlan) {
          this.showPlanNotSelectableModal = true;
        } else {
          this.planToSwitchTo = this.freeText;
          this.showSwitchPlanConfirmationModal = true;
        }
      } else if (currentPlan === this.proText) {
        // pro plan -> handyman plan
        if (plan === this.handymanText) {
          this.loadingHandymanPlanCheckoutSession = true;

          const allowedToSelectPlan = await userAllowedToChangePlans(
            this.$store.getters.getUser.uid,
            HANDYMAN_PLAN_EQUIPMENT_LIMIT,
            HANDYMAN_PLAN_ENTRY_LIMIT,
            HANDYMAN_PLAN_GUIDE_LIMIT
          );

          this.loadingHandymanPlanCheckoutSession = false;
          if (!allowedToSelectPlan) {
            this.showPlanNotSelectableModal = true;
          } else {
            this.planToSwitchTo = this.handymanText;
            this.showSwitchPlanConfirmationModal = true;
          }
          // pro plan -> free plan
        } else if (plan === this.freeText) {
          this.loadingFreePlanCheckoutSession = true;

          const allowedToSelectPlan = await userAllowedToChangePlans(
            this.$store.getters.getUser.uid,
            FREE_PLAN_EQUIPMENT_LIMIT,
            FREE_PLAN_ENTRY_LIMIT,
            FREE_PLAN_GUIDE_LIMIT
          );

          this.loadingFreePlanCheckoutSession = false;
          if (!allowedToSelectPlan) {
            this.showPlanNotSelectableModal = true;
          } else {
            this.planToSwitchTo = this.freeText;
            this.showSwitchPlanConfirmationModal = true;
          }
        }
      }
    } else {
      // handyman plan -> pro plan
      if (currentPlan === this.handymanText && plan === this.proText) {
        this.planToSwitchTo = this.proText;
        this.showSwitchPlanConfirmationModal = true;
        return;
      }

      // free plan -> PAID plan
      if (currentPlan === this.freeText) {
        this.planToSwitchTo = plan;
        this.showSwitchPlanConfirmationModal = true;
      }
    }
  }

  async processFreePlanSelection() {
    // cancel existing paid subscription if one exists
    if (this.$store.getters.getStripeActiveSubscription !== null) {
      await this.$store.dispatch("cancelUserSubscription");

      // set active subscription back to null since cancelled
      this.$store.commit("setStripeActiveSubscription", null);
    }

    await updateUserAsFreeUser(this.$store.getters.getUser.uid, true);

    this.resetSelectPlanButtonLoading();
    this.$store.commit("setUserPlanChanged", true);
    this.$router.push("/equipment");
  }

  closeConfirmFreePlanSelectionModal(freePlanSelected: boolean) {
    this.showConfirmFreePlanSelectionModal = false;

    //user wants to move forward with free plan
    if (freePlanSelected) {
      this.loadingFreePlanCheckoutSession = true;
      this.processFreePlanSelection();
    }
  }

  async closeSwitchPlanConfirmationModal(switchPlans: boolean) {
    this.showSwitchPlanConfirmationModal = false;

    //user wants to switch plans
    if (switchPlans) {
      switch (this.planToSwitchTo) {
        // PAID plan -> free plan
        case this.freeText:
          this.loadingFreePlanCheckoutSession = true;
          this.processFreePlanSelection();
          break;

        // existing plan -> handyman plan
        case this.handymanText:
          this.loadingHandymanPlanCheckoutSession = true;

          // pro plan -> handyman plan
          if (this.$store.getters.getUserCurrentPlan === this.proText) {
            await this.$store.dispatch(
              "changeUserPaidPlan",
              HANDYMAN_PLAN_PRICE
            );

            // update vuex without having to reload app
            this.$store.commit("setUserCurrentPlan", this.handymanText);
            await getCustomersActiveSubscription(
              this.$store.getters.getUser.uid,
              true
            );

            this.$store.commit("setUserPlanChanged", true);
            this.$router.push("/equipment");
          } else {
            // free plan -> handyman plan
            const success = await this.$store.dispatch(
              "sendToStripeCheckoutSession",
              {
                userId: this.$store.getters.getUser.uid,
                planPrice: HANDYMAN_PLAN_PRICE,
              }
            );

            if (!success) {
              this.resetSelectPlanButtonLoading();
            }
          }

          break;

        // existing plan -> pro plan
        case this.proText:
          this.loadingProPlanCheckoutSession = true;

          // handyman plan -> pro plan
          if (this.$store.getters.getUserCurrentPlan === this.handymanText) {
            await this.$store.dispatch("changeUserPaidPlan", PRO_PLAN_PRICE);

            // update vuex without having to reload app
            this.$store.commit("setUserCurrentPlan", this.proText);
            await getCustomersActiveSubscription(
              this.$store.getters.getUser.uid,
              true
            );

            this.$store.commit("setUserPlanChanged", true);
            this.$router.push("/equipment");
          } else {
            // free plan -> pro plan
            const success = await this.$store.dispatch(
              "sendToStripeCheckoutSession",
              {
                userId: this.$store.getters.getUser.uid,
                planPrice: PRO_PLAN_PRICE,
              }
            );

            if (!success) {
              this.resetSelectPlanButtonLoading();
            }
          }

          break;
        default:
          alert("This is not a valid plan");
          break;
      }
    }

    this.planToSwitchTo = "";
  }

  resetSelectPlanButtonLoading() {
    this.loadingFreePlanCheckoutSession = false;
    this.loadingHandymanPlanCheckoutSession = false;
    this.loadingProPlanCheckoutSession = false;
  }

  //scroll to bottom to bring entire panel into view when expanded
  scrollForPanel() {
    if (!this.proExpansionPanel.isActive) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 300); //give time for expansion panel opening animation
    }
  }
}
