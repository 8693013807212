export const FREE_TEXT = "Free";
export const HANDYMAN_TEXT = "Handyman";
export const PRO_TEXT = "Professional";

export const FREE_PLAN_COST = 0;
export const HANDYMAN_PLAN_COST = 10;
export const PRO_PLAN_COST = 30;

//Stripe live products
export const HANDYMAN_PLAN_PRICE = "price_1K39yWK3y0io9JfBZ0Ib3ipp";
export const PRO_PLAN_PRICE = "price_1K2jZpK3y0io9JfBY9tJQi5g";

//Stripe test products
export const HANDYMAN_PLAN_PRICE_TEST = "price_1K4B1EK3y0io9JfBqLXBn8tF";
export const PRO_PLAN_PRICE_TEST = "price_1K4AztK3y0io9JfB32Daji44";

export const FREE_PLAN_EQUIPMENT_LIMIT = 5;
export const FREE_PLAN_ENTRY_LIMIT = 10;
export const FREE_PLAN_GUIDE_LIMIT = 2;
export const HANDYMAN_PLAN_EQUIPMENT_LIMIT = 10;
export const HANDYMAN_PLAN_ENTRY_LIMIT = 20;
export const HANDYMAN_PLAN_GUIDE_LIMIT = 5;
