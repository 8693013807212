//File used to manage all calls made to firestore

import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  ref,
} from "@firebase/storage";
import { deleteUser, updateEmail, updatePassword } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { uploadBytes } from "firebase/storage";
import _ from "lodash";
import moment from "moment";
import { FREE_TEXT } from "./constants";
import { db, storage, auth } from "./firebase";
import store from "./store";

//SECTION: Apis
export async function getAllUserEquipment(
  userId: string
): Promise<Array<DocumentData>> {
  // Create a reference to the equipment collection
  const equipmentRef = collection(db, "equipment");

  // Create a query against the collection.
  const collectionQuery = query(equipmentRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(collectionQuery);

  //return data as an array for equipment table
  const data = querySnapshot.docs.map((doc) => {
    //include id for doc in object for doc
    return { ...doc.data(), id: doc.id };
  });

  return data;
}

export async function getSingleEquipment(equipmentId: string): Promise<any> {
  const docRef = doc(db, "equipment", equipmentId);
  const docSnap = await getDoc(docRef);

  const data = { ...docSnap.data(), id: docSnap.id };
  return data;
}

export async function getLogEntriesForEquipment(
  equipmentId: string
): Promise<Array<DocumentData>> {
  const logEntriesRef = collection(db, "logEntries");

  const collectionQuery = query(
    logEntriesRef,
    where("equipmentId", "==", equipmentId),
    orderBy("details.dateOfService", "desc")
  );
  const querySnapshot = await getDocs(collectionQuery);

  const data = querySnapshot.docs.map((doc) => {
    //include id for doc in object for doc
    return {
      ...doc.data(),
      id: doc.id,
    };
  });

  return data;
}

export async function getGuidesForEquipment(
  equipmentId: string
): Promise<Array<DocumentData>> {
  const guidesRef = collection(db, "guides");

  const collectionQuery = query(
    guidesRef,
    where("equipmentId", "==", equipmentId)
  );
  const querySnapshot = await getDocs(collectionQuery);

  //return data as an array for equipment table
  const data = querySnapshot.docs.map((doc) => {
    //include id for doc in object for doc
    return { ...doc.data(), id: doc.id };
  });

  return data;
}

export async function addNewEquipment(
  detailsFields: any[],
  category: string,
  userId: string
): Promise<void> {
  const equipmentToAdd = {
    userId: userId,
    category: category,
    dateAdded: new Date(),
    lastEntryDate: null,
    name: detailsFields[0].value,
    details: buildDetailsMap(detailsFields),
  };

  // Add a new document with a generated id.
  await addDoc(collection(db, "equipment"), equipmentToAdd);
}

export async function updateExistingEquipment(
  equipmentId: string,
  detailsFields: any[]
): Promise<void> {
  const equipmentToUpdate = {
    name: detailsFields[0].value,
    details: buildDetailsMap(detailsFields),
  };

  const docRef = doc(db, "equipment", equipmentId);
  await updateDoc(docRef, equipmentToUpdate);
}

export async function updateLastEntryDateForExistingEquipment(
  equipmentId: string,
  nullifyValue = false
): Promise<void> {
  const equipmentToUpdate = {
    lastEntryDate: nullifyValue ? null : new Date(),
  };

  const docRef = doc(db, "equipment", equipmentId);
  await updateDoc(docRef, equipmentToUpdate);
}

export async function deleteEquipment(
  equipmentId: string,
  userId: string
): Promise<void> {
  await deleteDoc(doc(db, "equipment", equipmentId));

  //delete log entries for equipment
  const entriesToDelete = await getLogEntriesForEquipment(equipmentId);
  _.forEach(entriesToDelete, async (entry) => {
    await deleteLogEntry(entry.id);

    //delete file if entry has a file
    if (entry.details.imageOrFile && entry.details.imageOrFile !== "") {
      await deleteFileFromStorage(
        `${userId}/entries/${entry.id}/${entry.details.imageOrFile}`
      );
    }
  });

  //delete guides for equipment
  const guidesToDelete = await getGuidesForEquipment(equipmentId);
  _.forEach(guidesToDelete, async (guide) => {
    await deleteGuide(guide.id);
  });
}

export async function addNewGuide(
  equipmentId: string,
  guideName: string,
  guideNotes: string
): Promise<void> {
  const guideToAdd = {
    dateAdded: new Date(),
    equipmentId: equipmentId,
    guideName: guideName,
    guideNotes: guideNotes,
  };

  await addDoc(collection(db, "guides"), guideToAdd);
}

export async function updateExistingGuide(
  guideId: string,
  guideName: string,
  guideNotes: string
): Promise<void> {
  const docRef = doc(db, "guides", guideId);

  const guideToUpdate = {
    guideName: guideName,
    guideNotes: guideNotes,
  };

  await updateDoc(docRef, guideToUpdate);
}

export async function deleteGuide(guideId: string): Promise<void> {
  await deleteDoc(doc(db, "guides", guideId));
}

export async function addNewLogEntry(
  equipmentId: string,
  logEntryFields: any
): Promise<string> {
  const logEntryToAdd = {
    equipmentId: equipmentId,
    details: buildDetailsMap(logEntryFields),
  };

  const newDoc = await addDoc(collection(db, "logEntries"), logEntryToAdd);
  await updateLastEntryDateForExistingEquipment(equipmentId);

  return newDoc.id;
}

export async function updateExistingLogEntry(
  logEntryId: string,
  logEntryFields: any
): Promise<void> {
  const logEntryToUpdate = {
    details: buildDetailsMap(logEntryFields),
  };

  const docRef = doc(db, "logEntries", logEntryId);
  await updateDoc(docRef, logEntryToUpdate);
}

export async function removeFileNameFromEntryForFileDeleted(
  logEntryId: string
): Promise<void> {
  const logEntryToUpdate = {
    "details.imageOrFile": "",
  };

  const docRef = doc(db, "logEntries", logEntryId);
  await updateDoc(docRef, logEntryToUpdate);
}

export async function addFileToStorage(file, filePath: string): Promise<void> {
  const storageRef = ref(storage, filePath);

  await uploadBytes(storageRef, file);
  console.log("Uploaded a blob or file!");
}

export async function getFileFromStorage(filePath: string): Promise<void> {
  const url = await getDownloadURL(ref(storage, filePath));
  const metaData = await getMetadata(ref(storage, filePath));

  // This can be downloaded directly:
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = () => {
    const blob = xhr.response;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = metaData.name;
    link.click();
    URL.revokeObjectURL(link.href);
  };
  xhr.open("GET", url);
  xhr.send();
}

export async function deleteFileFromStorage(filePath: string): Promise<void> {
  const fileRef = ref(storage, filePath);

  // Delete the file
  deleteObject(fileRef)
    .then(() => {
      console.log("file deleted");
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function deleteLogEntry(logEntryId: string): Promise<void> {
  await deleteDoc(doc(db, "logEntries", logEntryId));
}

export async function getUserProfileInformation(
  userId: string
): Promise<DocumentData> {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
}

export async function updateUserProfileInformation(
  userId: string,
  newProfileData
): Promise<void> {
  const docRef = doc(db, "users", userId);
  await updateDoc(docRef, newProfileData);
}

export async function updateUserEmail(emailAddress: string): Promise<string> {
  let responseString = "";
  await updateEmail(auth.currentUser, emailAddress)
    .then(() => {
      responseString = "Email updated!";
    })
    .catch((error) => {
      responseString = error.code;
    });

  return responseString;
}

export async function updateUserFirstSignIn(userId: string): Promise<void> {
  //update vuex store so on refresh it does not show welcome modal again without need to query again
  store.commit("setUserProfile", {
    ...store.getters.getUserProfile,
    firstSignIn: false,
  });

  //update firestore
  const docRef = doc(db, "users", userId);
  await updateDoc(docRef, {
    firstSignIn: false,
  });
}

//set freeUser field for user in both vuex and firestore
export async function updateUserAsFreeUser(
  userId: string,
  freeUser: boolean
): Promise<void> {
  //update vuex store so change is reflected client side without having to do another query
  store.commit("setUserProfile", {
    ...store.getters.getUserProfile,
    freeUser: freeUser,
  });

  //set current plan in vuex to "free"
  if (freeUser) {
    store.commit("setUserCurrentPlan", FREE_TEXT);
  }

  //update firestore
  const docRef = doc(db, "users", userId);
  await updateDoc(docRef, { freeUser: freeUser });
}

//get customer's ACTIVE subscription if one exists. Only one active subscription will ever exist at a time.
//updateVuexStore used when switching paid plans
export async function getCustomersActiveSubscription(
  userId,
  updateVuexStore = false
): Promise<any> {
  // use onSnapshot to listen to subscriptions collection so I can update vuex with new active subscription
  if (updateVuexStore) {
    const q = query(
      collection(db, "users", `${userId}/subscriptions`),
      where("status", "==", "active")
    );

    onSnapshot(q, (querySnapshot) => {
      store.commit("setStripeActiveSubscription", querySnapshot.docs[0].data());
      return;
    });
  } else {
    // simply get and return active subscription without listening for any changes
    const q = query(
      collection(db, `users/${userId}/subscriptions`),
      where("status", "in", ["active"])
    );

    const querySnapshot = await getDocs(q);

    //no active subscription
    if (querySnapshot.empty) {
      console.log("no active subscription found");
      return null;
    }

    return querySnapshot.docs[0].data();
  }
}

export async function getLogEntryCountForEquipment(
  equipmentId: string
): Promise<number> {
  const logEntriesRef = collection(db, "logEntries");

  const collectionQuery = query(
    logEntriesRef,
    where("equipmentId", "==", equipmentId)
  );

  const querySnapshot = await getDocs(collectionQuery);
  return querySnapshot.size;
}

export async function userAllowedToChangePlans(
  userId: string,
  equipmentLimit: number,
  entryLimit: number,
  guideLimit: number
): Promise<boolean> {
  const userEquipmentList = await getAllUserEquipment(userId);

  // return false if user has more equipment than selected plan allows
  if (userEquipmentList.length > equipmentLimit) {
    return false;
  }

  const logEntriesRef = collection(db, "logEntries");
  const guidesRef = collection(db, "guides");

  for (let index = 0; index < userEquipmentList.length; index++) {
    const currentEquipment = userEquipmentList[index];

    const entryCollectionQuery = query(
      logEntriesRef,
      where("equipmentId", "==", currentEquipment.id)
    );

    const entryQuerySnapshot = await getDocs(entryCollectionQuery);
    const entriesCountForEquipment = entryQuerySnapshot.size;

    // return false if any equipment has more entries than selected plan allows per equipment
    if (entriesCountForEquipment > entryLimit) {
      return false;
    }

    const guideCollectionQuery = query(
      guidesRef,
      where("equipmentId", "==", currentEquipment.id)
    );

    const guideQuerySnapshot = await getDocs(guideCollectionQuery);
    const guidesCountForEquipment = guideQuerySnapshot.size;

    // return false if any equipment has more guides than selected plan allows per equipment
    if (guidesCountForEquipment > guideLimit) {
      return false;
    }
  }

  return true;
}

export async function resetUserPassword(newPassword: string): Promise<string> {
  let responseString = "";
  try {
    await updatePassword(auth.currentUser, newPassword);
    responseString = "Password changed!";
  } catch (error) {
    responseString = error["code"];
  }

  return responseString;
}

export async function deleteUserAccount(userId: string): Promise<void> {
  try {
    const userEquipment = await getAllUserEquipment(userId);

    _.forEach(userEquipment, async (equipment) => {
      await deleteEquipment(equipment.id, userId);
    });

    //remove document from users collection
    await deleteDoc(doc(db, "users", userId));

    //TODO: add functionality to reauth user if they have not logged in for a while and they try to delete their account https://firebase.google.com/docs/auth/web/manage-users?authuser=0#re-authenticate_a_user
    await deleteUser(auth.currentUser);
  } catch (error) {
    console.log(error);
  }
}

//SECTION: Helper Functions
function buildDetailsMap(detailsFields) {
  //filter out unused fields
  const details = detailsFields.filter((detail) => detail.value);
  const detailsObj = {};

  //build details obj
  details.forEach((detail) => {
    //convert to timestamp for better storage instead of storing as string. Needs to be a string for date of service field input
    if (detail.field?.type === "date") {
      //convert back to js date from moment since that is what firestore expects for a timestamp
      detail.value = moment(detail.value).toDate();
    }

    detailsObj[_.camelCase(detail.label)] = detail.value;
  });

  return detailsObj;
}
