
import UserInfoWrapper from "@/components/UserInfoWrapper.vue";
import { Vue, Component } from "vue-property-decorator";
import Snackbar from "../components/Snackbar.vue";
import DeleteAccountModal from "../components/modals/DeleteAccountModal.vue";
import ChangeEmailModal from "../components/modals/ChangeEmailModal.vue";
import ResetPasswordModal from "../components/modals/ResetPasswordModal.vue";
import UpdatePersonalInfoModal from "../components/modals/UpdatePersonalInfoModal.vue";
import { getUserProfileInformation } from "@/apis";
import { DocumentData } from "@firebase/firestore";

@Component(
    {
        name: "ProfileSettings", 
        components:
            { 
                UserInfoWrapper, 
                Snackbar,
                DeleteAccountModal,
                ChangeEmailModal,
                ResetPasswordModal,
                UpdatePersonalInfoModal
            } 
    })
export default class ProfileSettings extends Vue {

    showUpdatePersonalInfoModal = false;
    showChangeEmailModal = false;
    showResetPasswordModal = false;
    showDeleteAccountModal = false;

    showUpdatePersonalInfoOverlay = false;

    existingUserInfo: DocumentData = {};
    existingEmail = "";
    snackbarMessage = "";
    showSnackbar = false;
    loadingCustomerPortal = false;

    get usesPasswordProvider(): boolean {
        return this.$store.getters.getUser && this.$store.getters.getUser.providerData[0].providerId === "password";
    }

    async updatePersonalInfo(): Promise<void> {
        this.showUpdatePersonalInfoModal = true;
        this.showUpdatePersonalInfoOverlay = true;

        this.existingUserInfo = await getUserProfileInformation(this.$store.getters.getUser.uid);
        this.showUpdatePersonalInfoOverlay = false;
    }

    changeEmail(): void {
        this.existingEmail = this.$store.getters.getUser.email;
        this.showChangeEmailModal = true;
    }

    resetPassword(): void {
        this.showResetPasswordModal = true;
    }

    deleteAccount(): void {
        this.showDeleteAccountModal = true;
    }

    managePaymentMethod () {
        this.loadingCustomerPortal = true;
        //go to the customer portal
        this.$store.dispatch("sendToStripeCustomerPortal");
    }
    
    changeMyPlan() {
        this.$router.push("plans");
    }

    updatePersonalInfoModalHidden(saveClicked: boolean): void {
        this.showUpdatePersonalInfoModal = false;

        if (saveClicked) {
            this.snackbarMessage = "Personal Info Updated!";
            this.showSnackbar = true;
        }
    }

    resetPasswordModalHidden(saveClicked: boolean): void {
        this.showResetPasswordModal = false;

        if (saveClicked) {
            this.snackbarMessage = "Password successfully reset!";
            this.showSnackbar = true;
        }
    }
}
