
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "AskToViewAddIconModal" })
export default class AskToViewAddIconModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (viewIconModal: boolean): boolean { return viewIconModal; }

    hideModal(viewIconModal = false): void {
        this.modalHidden(viewIconModal);
    }
}
