
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "WelcomeModal" })
export default class WelcomeModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: String }) firstName;
    @Emit() modalHidden (takeATour: boolean): boolean { return takeATour; }

    hideModal(takeATour = false): void {
        this.modalHidden(takeATour);
    }
}
