
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "QuickTourModal" })
export default class QuickTourModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (): boolean { return true; }

    carouselIndex = 0;

    get onMobileDevice (): boolean {
        return this.$store.getters.getWindowWidth < 520;
    }

    hideModal(): void {
        this.modalHidden();
        setTimeout(() => {
            this.carouselIndex = 0;
        }, 500);
    }
}
