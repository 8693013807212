
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "OperatingSystemSelectionModal" })
export default class OperatingSystemSelectionModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() setOperatingSystem (operatingSystem: string): string { return operatingSystem; }
    @Emit() modalHidden (): boolean { return true; }

    hideModal(): void {
        this.modalHidden();
    }
}
