
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVdWeHnlQd2NWAZRict1r4h6R-L8fUqiI",
  authDomain: "diymechaniclog.com",
  projectId: "diymechaniclog-141a1",
  storageBucket: "diymechaniclog-141a1.appspot.com",
  messagingSenderId: "156635583099",
  appId: "1:156635583099:web:3b5ed4cf4387c3504548de",
  measurementId: "G-6Y2QSGESL1"
};

const app = initializeApp(firebaseConfig)

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(app);

// utils
const db = getFirestore()
const auth = getAuth();
// const analytics = getAnalytics();

//TODO: remove these events and put them where a user actually signs in and signs up
// logEvent(analytics, 'login');
// logEvent(analytics, 'sign_up');

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// export utils/refs
export {
  app,
  db,
  auth,
  googleProvider,
  facebookProvider,
  storage
}