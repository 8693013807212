
import UserInfoWrapper from "@/components/UserInfoWrapper.vue";
import { Vue, Component } from "vue-property-decorator";
import OperatingSystemSelectionModal from "../components/modals/OperatingSystemSelectionModal.vue";
import AddIconToHomeScreenModal from "../components/modals/AddIconToHomeScreenModal.vue";
import QuickTourModal from "../components/modals/QuickTourModal.vue";

@Component(
    {   name: "Help",
        components: { UserInfoWrapper, AddIconToHomeScreenModal, OperatingSystemSelectionModal, QuickTourModal }
    })
export default class Help extends Vue {
    showOperatingSystemSelectionModal = false;
    showAddIconToHomeScreenModal = false;
    selectedOperatingSystem = "";
    showQuickTourModal = false;

    viewFAQs(): void {
        this.$router.push("/faq");
    }

    viewOperatingSystemSelectionModal(): void {
        this.showOperatingSystemSelectionModal = true;
    }

    operatingSystemSelectionModalHidden(): void {
        this.showOperatingSystemSelectionModal = false;
    }

    setSelectedOperatingSystem(operatingSystem: string): void {
        this.selectedOperatingSystem = operatingSystem;
        this.showOperatingSystemSelectionModal = false;
        this.showAddIconToHomeScreenModal = true;
    }

    addIconToHomeScreenModalHidden(): void {
        this.showAddIconToHomeScreenModal = false;

        //TODO: Instead of using timeouts I could check to see when dialog is hidden from DOM.
        setTimeout(() => {
            this.selectedOperatingSystem = "";
        }, 500)
    }

    openViewSiteTourModal(): void {
        this.showQuickTourModal = true;
    }
}
