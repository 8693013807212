import moment from "moment";

export default class Vehicle {
  name = "Vehicle";
  detailFields: any = [
    {
      label: "Name",
      displayLabel: "Nickname",
      placeholder: "My Vehicle",
      value: "",
      type: "input",
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Make",
      placeholder: "Toyota",
      value: "",
      type: "input",
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Model",
      placeholder: "Camry",
      value: "",
      type: "input",
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    { label: "Year", value: null, type: "select" },
    {
      label: "VIN",
      placeholder: "JT2BF22K5V0077240",
      value: null,
      type: "input",
    },
    {
      label: "Color",
      placeholder: "Red",
      value: null,
      type: "input",
    },
    { label: "Notes", value: null, type: "textarea" },
  ];

  logEntryFields: any = [
    {
      label: "Date of Service",
      value: moment().format("YYYY-MM-DD"),
      field: { component: "input", type: "date" },
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Mileage",
      value: null,
      field: { component: "input", type: "tel" },
      valid: null,
      rules: [
        (value: any) =>
          new RegExp("^[0-9]+$").test(value) || "Must be a number.",
      ],
    },
    {
      label: "Battery Check",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Brake Service",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Cabin Air Filter",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Check Fluids",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Coolant System Flush",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Engine Air Filter",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Fuel Filter",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Oil Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Oil Filter Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Spark Plugs",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Tire Rotation",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Trans Filter Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Trans Fluid Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Other",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Other Description",
      value: null,
      field: { component: "input", type: null },
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Image or File",
      value: null,
      field: { component: "file", type: null },
    },
    {
      label: "Notes",
      value: null,
      field: { component: "textarea", type: null },
    },
  ];

  public validateDetails() {
    const nameObj = this.detailFields.find((obj: any) => obj.label === "Name");
    const makeObj = this.detailFields.find((obj: any) => obj.label === "Make");
    const modelObj = this.detailFields.find(
      (obj: any) => obj.label === "Model"
    );

    nameObj.value === "" ? (nameObj.valid = false) : (nameObj.valid = null);
    makeObj.value === "" ? (makeObj.valid = false) : (makeObj.valid = null);
    modelObj.value === "" ? (modelObj.valid = false) : (modelObj.valid = null);

    return !!(
      nameObj.valid === null &&
      makeObj.valid === null &&
      modelObj.valid === null
    );
  }

  public validateEntry() {
    /* Check for validation on required field(s) */
    const dateOfServiceObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Date of Service"
    );
    const mileageObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Mileage"
    );
    const otherObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Other"
    );
    const otherDescriptionObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Other Description"
    );

    dateOfServiceObj.value === ""
      ? (dateOfServiceObj.valid = false)
      : (dateOfServiceObj.valid = null);
    new RegExp("^[0-9]+$").test(mileageObj.value)
      ? (mileageObj.valid = null)
      : (mileageObj.valid = false);

    const otherDescriptionNotEntered =
      otherObj.value &&
      (otherDescriptionObj.value === null || otherDescriptionObj.value === "");
    otherDescriptionNotEntered
      ? (otherDescriptionObj.valid = false)
      : (otherDescriptionObj.valid = null);

    /* Check to make sure there is a value in one of the other fields */
    const oilChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Oil Change"
    );
    const oilFilterChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Oil Filter Change"
    );
    const transFilterChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Trans Filter Change"
    );
    const transFluidChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Trans Fluid Change"
    );
    const engineAirFilterObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Engine Air Filter"
    );
    const fuelFilterObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Fuel Filter"
    );
    const tireRotationObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Tire Rotation"
    );
    const brakeServiceObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Brake Service"
    );
    const sparkPlugsObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Spark Plugs"
    );
    const checkFluidsObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Check Fluids"
    );
    const coolantSystemFlushObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Coolant System Flush"
    );
    const batteryCheckObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Battery Check"
    );
    const cabinAirFilterObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Cabin Air Filter"
    );
    const fileObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Image or File"
    );
    const notesObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Notes"
    );

    let taskMarked;
    if (
      (oilChangeObj.value === null || oilChangeObj.value === false) &&
      (oilFilterChangeObj.value === null ||
        oilFilterChangeObj.value === false) &&
      (transFilterChangeObj.value === null ||
        transFilterChangeObj.value === false) &&
      (transFluidChangeObj.value === null ||
        transFluidChangeObj.value === false) &&
      (engineAirFilterObj.value === null ||
        engineAirFilterObj.value === false) &&
      (fuelFilterObj.value === null || fuelFilterObj.value === false) &&
      (tireRotationObj.value === null || tireRotationObj.value === false) &&
      (brakeServiceObj.value === null || brakeServiceObj.value === false) &&
      (sparkPlugsObj.value === null || sparkPlugsObj.value === false) &&
      (checkFluidsObj.value === null || checkFluidsObj.value === false) &&
      (coolantSystemFlushObj.value === null ||
        coolantSystemFlushObj.value === false) &&
      (batteryCheckObj.value === null || batteryCheckObj.value === false) &&
      (cabinAirFilterObj.value === null || cabinAirFilterObj.value === false) &&
      (otherObj.value === null || otherObj.value === false) &&
      (fileObj.value === null || fileObj.value === "") &&
      (notesObj.value === null || notesObj.value === "")
    ) {
      taskMarked = false;
    } else {
      taskMarked = true;
    }

    return {
      valid:
        dateOfServiceObj.valid === null &&
        mileageObj.valid === null &&
        otherDescriptionObj.valid === null,
      taskMarked: taskMarked,
    };
  }
}
