import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=278ee64c&scoped=true&"
import script from "./Details.ts?vue&type=script&lang=ts&"
export * from "./Details.ts?vue&type=script&lang=ts&"
import style0 from "./Details.scss?vue&type=style&index=0&id=278ee64c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278ee64c",
  null
  
)

export default component.exports