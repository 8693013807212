
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "ConfirmFreePlanSelectionModal" })
export default class ConfirmFreePlanSelectionModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (freePlanSelected: boolean): boolean { return freePlanSelected; }

    hideModal(freePlanSelected = false): void {
        this.modalHidden(freePlanSelected);
    }
}
