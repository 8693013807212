
import { FREE_TEXT, HANDYMAN_TEXT } from "@/constants";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "PlanUpgradeModal" })
export default class PlanUpgradeModal extends Vue {
    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: String }) category;
    @Emit() modalHidden (): boolean { return true; }

    get desiredActionText() {
        switch (this.category) {
            case "equipment":
                return "piece of equipment"
            case "entry":
                return "log entry"
            case "guide":
                return "guide"
            default:
                return "";
        }
    }

    get planRequiredText() {
        const currentSubscription = this.$store.getters.getUserCurrentPlan;
        switch (currentSubscription) {
            case FREE_TEXT:
                return "Handyman or Professional"
            case HANDYMAN_TEXT:
                return "Professional"
            default:
                return "";
        }
    }

    viewPlans() {
        this.$router.push("plans");
    }

    hideModal(): void {
        this.modalHidden();
    }
}
