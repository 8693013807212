import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/home/Home.vue";
import Landing from "../views/Landing.vue";
import { auth } from "../firebase";
import store from "../store";
import _ from "lodash";

//ensures no error when trying to navigate to current page in view
const originalPush: any = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

//used to determine whether or not to show landingWrapper in shared pages
function dynamicProp() {
  return { landingPage: !auth.currentUser };
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/equipment",
    name: "Home",
    component: Home,
    meta: {
      requiresAuthAndPlan: true,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: dynamicProp,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    props: dynamicProp,
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/plans",
    name: "Plans",
    props: dynamicProp,
    component: () => import("../views/Plans.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("../views/Help.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("../views/details/Details.vue"),
    meta: {
      requiresAuthAndPlan: true,
    },
  },
  {
    path: "/signin",
    name: "Sign In",
    component: () => import("../views/auth/SignIn.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/signup",
    name: "Sign Up",
    props: dynamicProp,
    component: () => import("../views/auth/SignUp.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "Password Reset",
    component: () => import("../views/auth/PasswordReset.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/forgot-password-email",
    name: "Password Reset Email",
    component: () => import("../views/auth/PasswordResetEmailSent.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/signup-email-sent",
    name: "Sign Up Email",
    component: () => import("../views/auth/SignUpEmailSent.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/profile-settings",
    name: "Profile Settings",
    component: () => import("../views/ProfileSettings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    props: dynamicProp,
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
  {
    path: "/terms-of-service",
    name: "Terms of Service",
    props: dynamicProp,
    component: () => import("../views/TermsOfService.vue"),
    meta: {
      disabledIfSignedIn: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresAuthAndPlan = to.matched.some(
    (x) => x.meta.requiresAuthAndPlan
  );

  const usersCurrentPlan = store.getters.getUserCurrentPlan;
  let userHasExistingPlan = false;

  /* on sign in with email/pass, usersCurrentPlan is not populated in time since app is already loaded
   so we check props from userProfile to see if user has existing plan at all since userProfile IS populated
   on sign in before entering app */
  if (usersCurrentPlan === "") {
    const userIsFreeUser = store.getters.getUserProfile?.freeUser;
    const userHasActivePaidSubscription = _.some(
      store.getters.getUserProfile?.subscriptions,
      { status: "active" }
    );

    userHasExistingPlan = userIsFreeUser || userHasActivePaidSubscription;
  }

  const disabledIfSignedIn = to.matched.some((x) => x.meta.disabledIfSignedIn);

  if ((requiresAuth || requiresAuthAndPlan) && !auth.currentUser) {
    next("/");
  } else if ((disabledIfSignedIn && auth.currentUser) || !to.name) {
    next("/equipment");
  } else if (
    requiresAuthAndPlan &&
    usersCurrentPlan === "" &&
    !userHasExistingPlan
  ) {
    next("/plans");
  } else {
    next();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 200);
});

export default router;
