
import { updateUserProfileInformation } from "@/apis";
import { PasswordProviderUserDocumentInterface } from "@/interfaces";
import { getReadableErrorMessage } from "@/utility";
import { Vue, Component, Prop, Emit, Ref, Watch } from "vue-property-decorator";

@Component({ name: "UpdatePersonalInfoModal" })
export default class UpdatePersonalInfoModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: Boolean, default: false }) showLoadingOverlay;
    @Prop() userProfileData;
    @Emit() modalHidden (saveClicked: boolean): boolean { return saveClicked; }
    @Ref() updateUserInfoFormRef!: any;
    @Watch('userProfileData')
    onUserProfileDataRetrieved (): void {
        //TODO: find a better way to do this
        this.userInfo.firstName = this.userProfileData.firstName;
        this.userInfo.lastName = this.userProfileData.lastName;
    }

    saving = false;
    errorMessage = "";
    userInfo: PasswordProviderUserDocumentInterface = {
        darkMode: false,
        firstName: "",
        lastName: ""
    };
    rules = { 
        required: value => !!value || 'Required.',
    };

    async saveProfileInfo(): Promise<void> {
        this.errorMessage = "";

        //validate form
        if (!this.updateUserInfoFormRef.validate()) {
            return;
        }

        this.saving = true;
        try {
            await updateUserProfileInformation(this.$store.getters.getUser.uid, this.userInfo);
            this.hideModal(true);

            this.saving = false;
        } catch (error: any) {
            this.errorMessage = getReadableErrorMessage(error.code)
            this.saving = false;
        }
    }

    hideModal(saveClicked = false): void {
        //TODO: is there a better way to do this?
        this.userInfo.firstName = "";
        this.userInfo.lastName = "";

        this.modalHidden(saveClicked);
    }
}
