import moment from "moment";

export default class SnowBlower {
  name = "Snow Blower";
  detailFields: any = [
    {
      label: "Name",
      displayLabel: "Nickname",
      placeholder: "My Snow Blower",
      value: "",
      type: "input",
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Make",
      placeholder: "Troy-Bilt",
      value: null,
      type: "input",
    },
    {
      label: "Model",
      placeholder: "Storm 2410",
      value: null,
      type: "input",
    },
    { label: "Notes", value: null, type: "textarea" },
  ];

  logEntryFields: any = [
    {
      label: "Date of Service",
      value: moment().format("YYYY-MM-DD"),
      field: { component: "input", type: "date" },
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Engine Air Filter",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Oil Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Oil Filter Change",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Spark Plugs",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Other",
      value: null,
      field: { component: "checkbox", type: null },
    },
    {
      label: "Other Description",
      value: null,
      field: { component: "input", type: null },
      valid: null,
      rules: [(value: any) => !!value || "Required."],
    },
    {
      label: "Image or File",
      value: null,
      field: { component: "file", type: null },
    },
    {
      label: "Notes",
      value: null,
      field: { component: "textarea", type: null },
    },
  ];

  public validateDetails() {
    const nameObj = this.detailFields.find((obj: any) => obj.label === "Name");

    nameObj.value === "" ? (nameObj.valid = false) : (nameObj.valid = null);

    return nameObj.valid === null;
  }

  public validateEntry() {
    /* Check for validation on required field(s) */
    const dateOfServiceObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Date of Service"
    );

    const otherObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Other"
    );
    const otherDescriptionObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Other Description"
    );

    dateOfServiceObj.value === ""
      ? (dateOfServiceObj.valid = false)
      : (dateOfServiceObj.valid = null);

    const otherDescriptionNotEntered =
      otherObj.value &&
      (otherDescriptionObj.value === null || otherDescriptionObj.value === "");
    otherDescriptionNotEntered
      ? (otherDescriptionObj.valid = false)
      : (otherDescriptionObj.valid = null);

    /* Check to make sure there is a value in one of the other fields */
    const oilChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Oil Change"
    );
    const oilFilterChangeObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Oil Filter Change"
    );
    const engineAirFilterObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Engine Air Filter"
    );
    const sparkPlugsObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Spark Plugs"
    );
    const fileObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Image or File"
    );
    const notesObj = this.logEntryFields.find(
      (obj: any) => obj.label === "Notes"
    );

    let taskMarked;
    if (
      (oilChangeObj.value === null || oilChangeObj.value === false) &&
      (oilFilterChangeObj.value === null ||
        oilFilterChangeObj.value === false) &&
      (engineAirFilterObj.value === null ||
        engineAirFilterObj.value === false) &&
      (sparkPlugsObj.value === null || sparkPlugsObj.value === false) &&
      (otherObj.value === null || otherObj.value === false) &&
      (fileObj.value === null || fileObj.value === "") &&
      (notesObj.value === null || notesObj.value === "")
    ) {
      taskMarked = false;
    } else {
      taskMarked = true;
    }

    return {
      valid:
        dateOfServiceObj.valid === null && otherDescriptionObj.valid === null,
      taskMarked: taskMarked,
    };
  }
}
