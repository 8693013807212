import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import AddEditEquipment from "../../components/AddEditEquipment.vue";
import AddEditLogEntry from "../../components/AddEditLogEntry.vue";
import * as EquipmentOptions from "../../classes/equipment";
import _ from "lodash";
import moment from "moment";
import {
  addNewGuide,
  deleteFileFromStorage,
  deleteGuide,
  deleteLogEntry,
  getGuidesForEquipment,
  getLogEntriesForEquipment,
  updateExistingGuide,
  updateLastEntryDateForExistingEquipment,
} from "@/apis";
import {
  convertToMileageFormat,
  hideUnusedFieldsOnMobile,
} from "../../utility";
import Snackbar from "../../components/Snackbar.vue";
import PlanUpgradeModal from "../../components/modals/PlanUpgradeModal.vue";
import {
  FREE_PLAN_ENTRY_LIMIT,
  FREE_PLAN_GUIDE_LIMIT,
  FREE_TEXT,
  HANDYMAN_PLAN_ENTRY_LIMIT,
  HANDYMAN_PLAN_GUIDE_LIMIT,
  PRO_TEXT,
} from "@/constants";

@Component({
  name: "Details",
  components: { AddEditEquipment, AddEditLogEntry, Snackbar, PlanUpgradeModal },
})
export default class Details extends Vue {
  @Ref() logEntriesTableRef!: any;
  @Ref() addGuideRef!: any;

  //Use since no hidden event for dialog. SetTimeout maintains animation
  @Watch("showGuideModal")
  onModalHidden() {
    if (!this.showGuideModal) {
      setTimeout(() => {
        this.closeGuideModal();
      }, 1);
    }
  }

  //declare prop for imported function to be used in vue file
  convertToMileageFormat = convertToMileageFormat;
  equipDetailFields: any[] = [
    {
      value: "actions",
      text: "Actions",
      sortable: false,
      cellClass: "col-action",
      width: 160,
      fixed: true,
    },
    { value: "category", text: "Category", sortable: false },
  ];

  logEntryFields: any[] = [
    {
      value: "actions",
      text: "Actions",
      sortable: false,
      cellClass: "col-action",
      width: 250,
    },
  ];

  guideFields: any[] = [
    {
      value: "actions",
      text: "Actions",
      sortable: false,
      cellClass: "col-action",
      width: 250,
    },
    { value: "guideName", text: "Guide Name" },
    { value: "dateAdded", text: "Date Added" },
  ];

  //TODO: can the number of properties here be reduced?
  equipmentDetails: any[] = [];
  equipmentLogEntries: any[] = [];
  equipmentGuides: any[] = [];
  excludedFields: any[] = ["Notes", "Image or File", "Other Description"];

  tab: any = null;
  showEquipmentModal = false;
  showLogEntryModal = false;
  showGuideModal = false;
  entryAction = "";
  guideAction = "";
  equipmentAction = "";
  showDeleteEntryModal = false;
  showDeleteGuideModal = false;
  detailTableLoading = false;
  entryTableLoading = false;
  guideTableLoading = false;
  readOnly = false;
  guideNameValue = "";
  guideNotesValue = "";
  guideRules: any[] = [];

  currentRowData: any = {};
  equipmentClassData!: any;
  logEntryClassData: any = {};
  deleting = false;
  saving = false;
  moment = moment;
  entryDeletedSnackbar = false;
  planUpgradeRequired = false;
  planUpgradeCategory = "";
  forceReRenderLogEntryTableKey = 0;

  get windowWidth(): number {
    return this.$store.getters.getWindowWidth;
  }

  get selectedEquipment() {
    return this.$store.getters.getSelectedEquipment;
  }

  //TODO: make this a property in the vuex store
  get onMobileDevice(): boolean {
    return this.$store.getters.getWindowWidth < 600;
  }

  async beforeMount(): Promise<void> {
    this.generateDetailColumns();
    this.generateLogEntryColumns();
    this.getEquipmentDetails();
    await this.getEquipmentLogEntries();

    if (this.onMobileDevice) hideUnusedFieldsOnMobile(this.logEntriesTableRef);
    await this.getGuides();
  }

  getEquipmentDetails(): void {
    //flatten data for details table
    this.equipmentDetails = [
      {
        ...this.selectedEquipment.details,
        category: this.selectedEquipment.category,
        lastEntryDate: this.selectedEquipment.lastEntryDate,
        dateAdded: this.selectedEquipment.dateAdded,
      },
    ];
  }

  //TODO: could this be set to default to true/false so I can get rid of these big if/thens?
  async getEquipmentLogEntries(refreshEntriesTable?: boolean): Promise<void> {
    if (refreshEntriesTable === undefined) {
      this.detailTableLoading = true;
      this.entryTableLoading = true;
    } else {
      refreshEntriesTable
        ? (this.entryTableLoading = true)
        : (this.detailTableLoading = true);
    }

    //flatten data for log entries table
    const entries = await getLogEntriesForEquipment(this.selectedEquipment.id);
    this.equipmentLogEntries = entries.map((entry) => {
      return {
        ...entry.details,
        id: entry.id,
      };
    });

    if (refreshEntriesTable === undefined) {
      this.detailTableLoading = false;
      this.entryTableLoading = false;
    } else {
      refreshEntriesTable
        ? (this.entryTableLoading = false)
        : (this.detailTableLoading = false);
    }

    if (refreshEntriesTable) {
      //re-render table to get all data in again then I can hide unused fields again
      this.forceReRenderLogEntryTableKey++;

      if (this.onMobileDevice)
        setTimeout(() => {
          hideUnusedFieldsOnMobile(this.logEntriesTableRef);
        }, 1);
    }
  }

  //Build detail columns from category's class based on category name
  generateDetailColumns(): void {
    const className = this.selectedEquipment.category.replace(/ /g, "");
    this.equipmentClassData = new EquipmentOptions[`${className}`]();

    this.equipmentClassData.detailFields.forEach((field: any) => {
      if (field.label !== "Notes") {
        this.equipDetailFields.push({
          value: _.camelCase(field.label),
          text: field.displayLabel || field.label,
          sortable: false,
        });
      }
    });

    //TODO: find a way to simplify this
    this.equipDetailFields.push({
      value: "dateAdded",
      text: "Date Added",
      sortable: false,
      class: "col-date-added",
    });
    this.equipDetailFields.push({
      value: "lastEntryDate",
      text: "Last Entry Date",
      sortable: false,
      class: "col-entry-date",
    });
  }

  //Build log entry columns from category's class based on category name
  generateLogEntryColumns(): void {
    const className = this.selectedEquipment.category.replace(/ /g, "");
    this.logEntryClassData = new EquipmentOptions[`${className}`]();

    this.logEntryClassData.logEntryFields.forEach((field: any) => {
      if (!_.includes(this.excludedFields, field.label)) {
        this.logEntryFields.push(this.generateColumn(field));
      }
    });
  }

  //generate each field and include class dynamically for custom scss
  generateColumn(field: any) {
    const fieldClass = `col-${_.kebabCase(field.label)}`;

    return {
      value: _.camelCase(field.label),
      text: field.label,
      class: fieldClass,
    };
  }

  setCurrentRowData(cellData: any): void {
    this.currentRowData = cellData;
  }

  openEquipmentModal(cellData: any, readOnly: boolean): void {
    this.setEquipmentClassData();
    this.readOnly = readOnly;
    readOnly
      ? (this.equipmentAction = "View")
      : (this.equipmentAction = "Edit");
    this.showEquipmentModal = true;
  }

  //assigns values to class object for equipment based on data from detail table to be used in add edit equip modal
  setEquipmentClassData(): void {
    const detailData = this.equipmentDetails[0]; // There will only ever be one element here for header detail data

    for (const field of this.equipmentClassData.detailFields) {
      const labelVal = _.camelCase(field.label); // get label for current field from equipment class

      /* set value in class field from grid data based off key */
      for (const key in detailData) {
        if (labelVal === key) {
          field.value = detailData[key];
          break;
        } else {
          //reset fields with any values entered by user but not saved
          field.value = null;
        }
      }
    }
  }

  editEquipment(): void {
    this.readOnly = false;
    this.equipmentAction = "Edit";
  }

  closeEquipmentModal(): void {
    this.showEquipmentModal = false;
  }

  openLogEntryModal(newEntry: boolean, readOnly?: boolean): void {
    if (newEntry) {
      const currentPlan = this.$store.getters.getUserCurrentPlan;

      // check for non pro users
      if (currentPlan !== PRO_TEXT) {
        const entryLimit =
          currentPlan === FREE_TEXT
            ? FREE_PLAN_ENTRY_LIMIT
            : HANDYMAN_PLAN_ENTRY_LIMIT;

        if (this.equipmentLogEntries.length === entryLimit) {
          this.planUpgradeCategory = "entry";
          this.planUpgradeRequired = true;
          return;
        }
      }
    }

    //HACK: Find a better way to do this
    /* setTimeout ensures that this.currentRowData is set before setLogEntryClassData function is called from clicking icon. dont
    do this for equipment details since there is only ever 1 row */
    setTimeout(() => {
      if (newEntry) {
        this.entryAction = "Add";
        this.currentRowData = {};
      } else {
        readOnly ? (this.entryAction = "View") : (this.entryAction = "Edit");
      }

      this.readOnly = readOnly;

      //get new class instance to generate modal
      const className = this.selectedEquipment.category.replace(/ /g, "");
      this.logEntryClassData = new EquipmentOptions[`${className}`]();

      if (!newEntry) {
        //Set class data based on row data if Edit Log Entry icon clicked
        this.setLogEntryClassData();
      }

      this.showLogEntryModal = true;
    }, 1);
  }

  //assigns values to class object for log entry based on data from current row to be used in log entry modal
  setLogEntryClassData(): void {
    for (const field of this.logEntryClassData.logEntryFields) {
      //get label for current field
      const labelVal = _.camelCase(field.label);

      //set value in class field from grid data based off key
      for (const key in this.currentRowData) {
        if (labelVal === key) {
          const cellData = this.currentRowData[key];

          if (labelVal === "dateOfService") {
            //convert from firestore timestamp to string for displaying in date of service field input
            field.value = moment.unix(cellData.seconds).format("YYYY-MM-DD");
          } else {
            field.value = cellData;
          }

          break;
        } else {
          //reset fields with any values entered by user but not saved
          field.value = null;
        }
      }
    }
  }

  editEntry(): void {
    this.readOnly = false;
    this.entryAction = "Edit";
  }

  closeLogEntryModal(): void {
    this.currentRowData = {};
    this.showLogEntryModal = false;

    //setTimeout used so fields are not removed before modal is closed. Looks a lot cleaner this way
    setTimeout(() => {
      this.logEntryClassData = {};
    }, 200);
  }

  async deleteEntry(): Promise<void> {
    this.deleting = true;
    await deleteLogEntry(this.currentRowData.id);

    if (
      this.currentRowData.imageOrFile &&
      this.currentRowData.imageOrFile !== ""
    ) {
      //delete file for entry
      const filePath = `${this.$store.getters.getUser.uid}/entries/${this.currentRowData.id}/${this.currentRowData.imageOrFile}`;
      await deleteFileFromStorage(filePath);
    }

    this.showDeleteEntryModal = false;

    if (this.equipmentLogEntries.length === 1) {
      this.equipmentLogEntries = [];
      this.equipmentDetails[0].lastEntryDate = null;

      await updateLastEntryDateForExistingEquipment(
        this.selectedEquipment.id,
        true
      );
    } else {
      this.equipmentLogEntries = _.filter(
        this.equipmentLogEntries,
        (entry) => entry.id !== this.currentRowData.id
      );
    }

    this.entryDeletedSnackbar = true;
    this.deleting = false;
  }

  async getGuides(): Promise<void> {
    this.guideTableLoading = true;

    this.equipmentGuides = await getGuidesForEquipment(
      this.selectedEquipment.id
    );

    this.guideTableLoading = false;
  }

  openGuideModal(newGuide: boolean, readOnly?: boolean): void {
    if (newGuide) {
      const currentPlan = this.$store.getters.getUserCurrentPlan;

      // check for non pro users
      if (currentPlan !== PRO_TEXT) {
        const guideLimit =
          currentPlan === FREE_TEXT
            ? FREE_PLAN_GUIDE_LIMIT
            : HANDYMAN_PLAN_GUIDE_LIMIT;

        if (this.equipmentGuides.length === guideLimit) {
          this.planUpgradeCategory = "guide";
          this.planUpgradeRequired = true;
          return;
        }
      }
    }

    setTimeout(() => {
      if (newGuide) {
        this.guideAction = "Add";
      } else {
        readOnly ? (this.guideAction = "View") : (this.guideAction = "Edit");
        this.guideNameValue = this.currentRowData.guideName;
        this.guideNotesValue = this.currentRowData.guideNotes;
      }

      this.readOnly = readOnly;
      this.showGuideModal = true;
    }, 1);
  }

  editGuide(): void {
    this.readOnly = false;
    this.guideAction = "Edit";
  }

  async saveGuide(): Promise<void> {
    //Validate based on rules within class to update UI
    this.guideRules = [(value: string) => !!value || "Required."];
    this.addGuideRef.validate();

    if (!this.guideNameValue || !this.guideNotesValue) {
      return;
    }

    this.saving = true;
    this.guideAction === "Edit"
      ? await updateExistingGuide(
          this.currentRowData.id,
          this.guideNameValue,
          this.guideNotesValue
        )
      : await addNewGuide(
          this.selectedEquipment.id,
          this.guideNameValue,
          this.guideNotesValue
        );

    this.showGuideModal = false;
    await this.getGuides();
    this.saving = false;
  }

  async deleteGuide(): Promise<void> {
    this.deleting = true;

    await deleteGuide(this.currentRowData.id);
    this.showDeleteGuideModal = false;
    await this.getGuides();

    this.deleting = false;
  }

  closeGuideModal(): void {
    this.guideNameValue = "";
    this.guideNotesValue = "";
    this.currentRowData = {};
    this.guideRules = [];
  }

  closePlanUpgradeRequiredModal() {
    this.planUpgradeRequired = false;
    this.planUpgradeCategory = "";
  }
}
