
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "AddIconToHomeScreenModal" })
export default class AddIconToHomeScreenModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: String }) selectedOperatingSystem;
    @Emit() modalHidden (): boolean { return true; }

    stepInView = 1;

    get onMobileDevice (): boolean {
        return this.$store.getters.getWindowWidth < 520;
    }

    get appleSelected(): boolean {
        return this.selectedOperatingSystem === "apple";
    }

    get modalSubTitle(): string {
        return this.appleSelected ? "Apple" : "Android";
    }

    get stepOneHeaderText(): string {
        return this.appleSelected ? "Click Share Button" : "Click Menu Button";
    }

    get stepOneImage(): string {
        return this.appleSelected ? "iPhone-app-icon-step-1.png" : "android-app-icon-step-1.png";
    }

    get stepTwoImage(): string {
        return this.appleSelected ? "iPhone-app-icon-step-2.png" : "android-app-icon-step-2.png";
    }

    get stepThreeImage(): string {
        return this.appleSelected ? "iPhone-app-icon-step-3.png" : "android-app-icon-step-3.png";
    }

    next(): void {
        switch (this.stepInView) {
            case 1:
                this.stepInView = 2;
                return;
            case 2:
                this.stepInView = 3;
                return;
            case 3:
                this.stepInView = 1;
                return;
        }
    }

    previous(): void {
        switch (this.stepInView) {
            case 1:
                this.stepInView = 3;
                return;
            case 2:
                this.stepInView = 1;
                return;
            case 3:
                this.stepInView = 2;
                return;
        }

    }

    hideModal(): void {
        this.modalHidden();
        setTimeout(() => {
            this.stepInView = 1;
        }, 500)
    }
}
