
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({ name: "CurrentPlanModal" })
export default class CurrentPlanModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Emit() modalHidden (): boolean { return true; }

    hideModal(): void {
        this.modalHidden();
    }
}
