
import { Vue, Component, Ref } from "vue-property-decorator";
import { emailRulePattern, getReadableErrorMessage, contactUsMailToString } from "../../utility"
import UserInfoWrapper from "../../components/UserInfoWrapper.vue"
import { SignInInterface } from "@/interfaces";

@Component({ name: "SignIn", components: { UserInfoWrapper } })
export default class SignIn extends Vue {

    @Ref() signInFormRef!: any;

    viewPassword = false;
    signingIn = false;
    errorMessage = "";
    emailVerificationRequired = false;
    sendingAnotherVerificationEmail = false;
    contactUsMailToString = contactUsMailToString;
    signInForm: SignInInterface = {
        email: "",
        password: ""
    }

    rules = { 
        required: value => !!value || 'Required.',
        email: value => {
            return emailRulePattern.test(value) || 'Invalid e-mail.'
        }
    }

    async signInWithEmailPassword(): Promise<void> {
        this.errorMessage = "";
        this.emailVerificationRequired = false;

        //validate sign in form
        if (!this.signInFormRef.validate()) {
            return;
        }

        try {
            this.signingIn = true;
            //sign in user to get user info from initial sign up
            await this.$store.dispatch("signInWithEmailPassword", this.signInForm);

            //check for email verification
            if (!this.$store.getters.getUser.emailVerified) {
                this.emailVerificationRequired = true;
                await this.$store.dispatch("signOut");
                this.signingIn = false;
                return;
            }

            //leave user at plans page if they selected a plan while signed out
            if (this.$store.getters.getPlanSelectedWhileSignedOut === null) {
                this.$router.push("/equipment");
                this.signingIn = false;
            } else {
                //reset value since no longer needed
                // this.$store.commit("setPlanSelectedWhileSignedOut", null);
            }
        } catch (error: any) {
            this.errorMessage = getReadableErrorMessage(error.code);
            this.signingIn = false;
        }
    }
    
    async signInWithGoogle(): Promise<void> {
        try {
            // TODO: remove this eventually and get it working so I can redirect user to plans when they select a plan signed out
            // FIXME: temporarily resetting this so app does not freak out if user select a plan when signed out then signs in via third party
            if (this.$store.getters.getPlanSelectedWhileSignedOut !== null) {
                this.$store.commit("setPlanSelectedWhileSignedOut", null);
            }
            await this.$store.dispatch("signInWithGoogle");
        } catch (error) {
            console.log(error);
        }
    }

    async signInWithFacebook(): Promise<void> {
        try {
            // TODO: remove this eventually and get it working so I can redirect user to plans when they select a plan signed out
            // FIXME: temporarily resetting this so app does not freak out if user select a plan when signed out then signs in via third party
            if (this.$store.getters.getPlanSelectedWhileSignedOut !== null) {
                this.$store.commit("setPlanSelectedWhileSignedOut", null);
            }
            await this.$store.dispatch("signInWithFacebook");
        } catch (error) {
            console.log(error);
        }
    }

    async resendEmailVerification(): Promise<void> {
        this.sendingAnotherVerificationEmail = true;

        //sign in user to get user info from initial sign up
        await this.$store.dispatch("signInWithEmailPassword", this.signInForm);

        //send email verification email
        await this.$store.dispatch("sendUserEmailVerification");

        //sign user out
        //setTimeout needed to give firebase time to process prior requests
        setTimeout(async () => {
            await this.$store.dispatch("signOut");
            this.$router.push("/signup-email-sent");
            this.sendingAnotherVerificationEmail = false;
        }, 1000);
    }

    navigateToPage(page: string): void {
        this.$router.push(page);
    }
}
