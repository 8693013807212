
import { emailRulePattern, getReadableErrorMessage } from "@/utility";
import { Vue, Component, Ref } from "vue-property-decorator";
import UserInfoWrapper from "../../components/UserInfoWrapper.vue"

@Component({ name: "PasswordReset", components: { UserInfoWrapper } })
export default class PasswordReset extends Vue {

    @Ref() passwordResetFormRef!: any;

    resettingPassword = false;
    errorMessage = "";
    email = "";

    rules = { 
        required: value => !!value || 'Required.',
        email: value => {
            return emailRulePattern.test(value) || 'Invalid e-mail.'
        }
    }

    async resetPassword(): Promise<void> {
        this.errorMessage = "";

        //validate sign in form
        if (!this.passwordResetFormRef.validate()) {
            return;
        }

        this.resettingPassword = true;
        try {
            await this.$store.dispatch("sendUserPasswordResetEmail", this.email);
            this.resettingPassword = false;
        } catch (error) {
            this.errorMessage = getReadableErrorMessage(error.code)
            this.resettingPassword = false;
        }
    }

    navigateBack(): void {
        this.$router.push('/signin');
    }
}
