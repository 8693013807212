
import { addNewEquipment, getSingleEquipment, updateExistingEquipment } from '@/apis';
import { buildYearList } from '@/utility';
import { Vue, Component, Prop, Watch, Emit, Ref } from 'vue-property-decorator';

    @Component({ name: 'AddEditEquipment' })
export default class AddEditEquipment extends Vue {
    @Prop({ default: false })
    showModal: boolean;

    @Prop({ default: null })
    equipmentId: string;

    @Prop({ default: {} })
    equipmentData: any;

    @Prop({ default: '' })
    action: string;

    @Prop({ default: false })
    readOnly: boolean;

    @Ref()addEquipRef!: any;

    @Watch('showModal')
    onShowModalChanged (): void {
        if (this.showModal) {
            this.modalVisible = true;
        } else {
            this.modalVisible = false;
        }
    }

    //Use since no hidden event for dialog. SetTimeout maintains animation
    @Watch('modalVisible')
    onModalHidden (): void {
        if (!this.modalVisible) {
            setTimeout(() => {
                this.closeModal();
            }, 1);
        }
    }

    @Emit() refreshEquipDetails (): boolean { return true; }
    @Emit() editEquipment (): boolean { return true; }

    yearOptions: any[] = [];
    modalVisible = false;
    $parent: any;
    saving = false;

    beforeMount (): void {
        this.yearOptions = buildYearList();
    }

    async saveEquipment (): Promise<void> {
        //Validate based on rules within class to update UI
        this.addEquipRef.validate();

        //Check values using class method to prevent from saving
        const validationResult = this.equipmentData.validateDetails();
        if (!validationResult) {
            return;
        }

        this.saving = true;
        if (this.equipmentId !== null) {
            await updateExistingEquipment(this.equipmentId, this.equipmentData.detailFields);
        } else {
            await addNewEquipment(this.equipmentData.detailFields, this.equipmentData.name, this.$store.getters.getUser.uid);
        }

        if (this.equipmentId !== null) {
            //get new equipment data to update selected equipment in store to update breadcrumb
            const data =  await getSingleEquipment(this.equipmentId);
            this.$store.commit('setSelectedEquipment', data);
        }

        this.closeModal();
        this.refreshEquipDetails();

        this.saving = false;
    }

    closeModal (): void {
        this.$parent.closeEquipmentModal();
    }
}
