
import { updateUserEmail } from "@/apis";
import { emailRulePattern, getReadableErrorMessage } from "@/utility";
import { Vue, Component, Prop, Emit, Ref } from "vue-property-decorator";

@Component({ name: "ChangeEmailModal" })
export default class ChangeEmailModal extends Vue {

    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: String, default: "" }) existingEmail;
    @Emit() modalHidden (): boolean { return true; }
    @Ref() changeEmailFormRef!: any;

    saving = false;
    newEmail = "";
    emailForMatch = "";
    errorMessage = "";
    rules = {
        required: value => !!value || 'Required.',
        email: value => {
            return emailRulePattern.test(value) || 'Invalid e-mail.'
        }
    }

    async saveNewEmail(): Promise<void> {
        this.errorMessage = "";

        //validate form
        if (!this.changeEmailFormRef.validate()) {
            return;
        } else if (this.newEmail !== this.emailForMatch) {
            this.errorMessage = "Emails do not match."
            return;
        }

        this.saving = true;
        const responseString = await updateUserEmail(this.newEmail);

        //error when updating
        if (responseString !== "Email updated!") {
            this.errorMessage = getReadableErrorMessage(responseString);
            this.saving = false;
            return;
        }

        //reset to light theme
        this.$vuetify.theme.dark = false;
        const userProfile = this.$store.getters.getUserProfile;
        userProfile.darkMode = this.$vuetify.theme.dark;

        await this.$store.dispatch("signOut");
        //TODO: Finish this functionality
        //LEFT OFF HERE TRYING TO FIGURE OUT HOW TO SIGN OUT A USER WHEN CHANGING THEIR EMAIL ADDRESS

        await this.$store.dispatch("sendUserEmailVerification");

        this.saving = false;
    }

    hideModal(): void {
        this.newEmail = "";
        this.emailForMatch = "";
        this.errorMessage = "";
        this.modalHidden();
    }
}
