import _ from "lodash";

export function getReadableErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case "auth/user-not-found":
      return "User not found. Please try again.";
    case "auth/user-disabled":
      return "User account has been disabled. Please contact the admin.";
    case "auth/wrong-password":
      return "Password is incorrect. Please try again.";
    case "auth/email-already-in-use":
      return "Email is already in use. Please use a different email address.";
    case "auth/weak-password":
      return "Password is too weak. Please try using a stronger password.";
    case "auth/requires-recent-login":
      return "Recent sign in required. Please sign out and sign back in, then try again.";
    default:
      return "An unknown error has occurred. Please try again.";
  }
}

export function hideUnusedFieldsOnMobile(tableReference) {
  const tableDataNodes =
    tableReference.$el.children[0].children[0].children[2].children;

  _.forEach(tableDataNodes, (row) => {
    _.forEach(row.cells, (cell) => {
      if (!cell.lastChild.lastChild) {
        cell.style.display = "none";
      }
    });
  });
}

export function scrollToElement(elementId: string): void {
  setTimeout(() => {
    const elmnt = document.getElementById(elementId);
    elmnt.scrollIntoView();
  }, 1);
}

//Convert mileage format for cell template to make more readable
export function convertToMileageFormat(mileage: number): string {
  let mileageVal = mileage.toString();

  if (mileageVal) {
    mileageVal += "";
    const x = mileageVal.split(".");
    let x1 = x[0];
    const x2 = x.length > 1 ? "." + x[1] : "";
    const rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }

    return x1 + x2;
  } else {
    //if undefined then mileage was not provided so pass back empty string to display nothing
    return "";
  }
}

export function buildYearList(): any[] {
  const yearOptions = [];
  const currentYear = new Date().getFullYear();

  for (let x = currentYear; x >= 1950; x--) {
    yearOptions.push({ value: String(x), text: x });
  }

  return yearOptions;
}

/* fetches image so I can cache and to make for a faster UX. i.e. This way I don't have to wait for a modal to open to trigger
 * a fetch for the image. */
export function cacheImage(path: string) {
  const img = new Image();
  img.src = path;
}

export const emailRulePattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const contactUsMailToString =
  "mailto:diymechaniclog@gmail.com?subject=diymechaniclog: Contact Us";
