
import { Vue, Component, Ref } from "vue-property-decorator";
import { emailRulePattern, getReadableErrorMessage, contactUsMailToString } from "../../utility";
import UserInfoWrapper from "../../components/UserInfoWrapper.vue";
import { UserDataInterface } from "@/interfaces";

@Component({ name: "SignUp", components: { UserInfoWrapper } })
export default class SignUp extends Vue {

    @Ref() signUpFormRef!: any;

    signingUp = false;
    errorMessage = "";
    passwordForMatch = "";
    contactUsMailToString = contactUsMailToString;
    signUpForm: UserDataInterface = {
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    }

    rules = { 
        required: value => !!value || 'Required.',
        email: value => {
            return emailRulePattern.test(value) || 'Invalid e-mail.'
        }
    }

    async signUp(): Promise<void> {
        this.errorMessage = "";

        //validate form and password match
        if (!this.signUpFormRef.validate()) {
            return;
        } else if (this.signUpForm.password !== this.passwordForMatch) {
            this.errorMessage = "Passwords do not match."
            return;
        }

        this.signingUp = true;
        try {
            //create user account
            await this.$store.dispatch("signUp", this.signUpForm);

            //send email for email address verification
            await this.$store.dispatch("sendUserEmailVerification");

            //sign user out so they must verify email first
            //setTimeout needed to give firebase time to process prior requests
            setTimeout(async () => {
                await this.$store.dispatch("signOut");
                this.signingUp = false;
                this.$router.push("/signup-email-sent");
            }, 1000);
        } catch (error: any) {
            this.errorMessage = getReadableErrorMessage(error.code)
            this.signingUp = false;
        }
    }

    async signInWithGoogle(): Promise<void> {
        try {
            await this.$store.dispatch("signInWithGoogle");
            this.$router.push("/equipment");
        } catch (error) {
            console.log(error)
        }
    }

    async signInWithFacebook(): Promise<void> {
        try {
            await this.$store.dispatch("signInWithFacebook");
            this.$router.push("/equipment");
        } catch (error) {
            console.log(error)
        }
    }

    viewPrivacyPolicy(): void {
        this.$router.push("/privacy-policy");
    }

    navigateToPage(page: string) {
        this.$router.push(page);
    }
}
