
  import _ from "lodash";
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";

  @Component({ name: "ShowPreviewModal" })
  export default class ShowPreviewModal extends Vue {
    @Prop({ type: Boolean, default: false }) showModal;
    @Prop({ type: String }) preview;
    @Watch('showModal')
    onShowModalChanged (): void {
      if (this.showModal) {
        this.showGif = true;
      }
    }
    @Emit() modalHidden(): boolean {
      return true;
    }

    showGif = false;

    get onMobileDevice(): boolean {
      return this.$store.getters.getWindowWidth < 520;
    }

    get modalTitle(): string {
      return _.startCase(this.preview);
    }

    get gifFileName(): string {
      return `${_.kebabCase(this.preview)}.gif`;
    }

    hideModal(): void {
      this.modalHidden();
      setTimeout(() => {
        this.showGif = false;
      }, 150);
    }
  }
